import * as React from "react";
import { Table, Box } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import RotateRightIcon from "@mui/icons-material/RotateRight";

export default function KeyInformationTable({ rows }) {
  if (rows?.length === 0) {
    return (
      <Box
        className="loaderPage"
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 2000,
        }}
      >
        <RotateRightIcon
          color="inherit"
          fontSize="large"
          sx={{ animation: "spin 2s infinite ease-in-out" }}
        />
      </Box>
    );
  }

  return (
    <TableContainer style={{ border: "none", paddingTop: "20px" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell
                component="th"
                scope="row"
                sx={
                  index === 0
                    ? {
                        color: "#19213D",
                        fontWeight: "bold",
                        paddingLeft: "30px",
                        fontSize: "14px",
                      }
                    : {
                        color: "#666F8D",
                        paddingLeft: "30px",
                        fontSize: "14px",
                      }
                }
              >
                {row?.name}:
              </TableCell>
              <TableCell
                align="left"
                sx={
                  index === 0
                    ? {
                        color: "#19213D",
                        borderLeft: "1px solid #E0E0E0",
                        paddingLeft: "30px",
                        fontSize: "14px",
                      }
                    : {
                        color: "#666F8D",
                        borderLeft: "1px solid #E0E0E0",
                        paddingLeft: "30px",
                        fontSize: "14px",
                      }
                }
              >
                {row?.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
