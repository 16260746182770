import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Tab,
  Tabs,
  DialogActions,
  DialogContent,
  Dialog,
  Tooltip,
  Grid,
  Typography,
  IconButton,
  AppBar,
  Checkbox,
  TextField,
  Button,
  DialogTitle,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useParams } from "react-router-dom";
import {
  getCimPage,
  getFeedback,
  view2PagerLists,
  acceptRejectRedraft,
  createReDraft,
  deleteBullets,
} from "../service";
import ReactMarkdown from "react-markdown";
import MarkdownIt from "markdown-it";
import "../index.css";
import Loader from "../../common/Loader";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useNavigate } from "react-router-dom";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import RotateRightIcon from "@mui/icons-material/RotateRight";

const md = new MarkdownIt();
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const KeyMeritsAndConsideration = ({
  data,
  section,
  redraftStatus,
  deleteBullet,
  handleDelete,
  cimDeleted,
}) => {
  const urlParams = useParams();
  const navigate = useNavigate();
  const params = urlParams.userId;
  const [open, setOpen] = useState(false);
  const [hovered, setHovered] = useState(null);
  const [subHovered, setSubHovered] = useState(null);
  const [selectedItemName, setSelectedItemName] = useState([]);
  const [value, setValue] = React.useState(0);
  const [base64, setBase64] = useState("");
  const [pageNumber, setPageNumber] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [thumbs, setThumbs] = useState({});
  const [thumbsConsideration, setThumbsConsideration] = useState({});
  const [preliminaryData, setPreliminaryData] = useState([]);
  const [dataSource, setDataSource] = useState();
  const firstButtonRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState("");
  const [adminEdit, setAdminEdit] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const parseMeritsAndConsiderations = (data) => {
    const formattedBullets = data?.map((item) => ({
      name: item?.name,
      description: item?.values?.map((value) => value?.result).join(" "),
      page: item?.values?.length > 0 ? item?.values[0]?.page_no : null,
      number: item?.number,
      feedback: item?.feedback,
      redrafted: item?.redrafted ? true : false,
      data_source:
        item?.values?.length > 0 ? item?.values[0]?.data_source : null,
      url: item?.values?.length > 0 ? item?.values[0]?.url : null,
      insights: item?.insights !== undefined ? item?.insights : null,
    }));
    return formattedBullets;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSelectedTab(event.target.innerText);
    getCimPageApi(
      params,
      _.find(selectedItemName, (item) =>
        item?.title?.includes(event.target.innerText)
      ).pageNo[0],
      _.find(selectedItemName, (item) =>
        item?.title?.includes(event.target.innerText)
      ).value
    );
  };
  useEffect(() => {
    // Auto-click the first button when the component mounts
    if (firstButtonRef.current) {
      firstButtonRef.current.click();
    }
  }, []);

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
      setSelectedItemName(null);
      setPageNumber(null);
      setValue(0);
      setMainTab(0);
      // setSelectedTab("CIM");
    }
  };

  const handleThumbsToggle = async (index, type, number, subNumber) => {
    setLoader(true);
    try {
      const response = await getFeedback(
        params,
        "III",
        number,
        subNumber,
        type ? "True" : "False"
      );
      // Handle response if necessary
      if (response.status === "success") {
        const resp = await view2PagerLists(3, params);
        const merits = parseMeritsAndConsiderations(
          resp?.data?.bullets[0]?.sub_bullets
        );
        const consideration = parseMeritsAndConsiderations(
          resp?.data?.bullets[1]?.sub_bullets
        );
        const keyHeaders = resp?.data?.bullets[0]?.name;
        const considerationHeaders = resp?.data?.bullets[1]?.name;
        setPreliminaryData({
          merits,
          consideration,
          keyHeaders,
          considerationHeaders,
        });
        setLoader(false);
      }
    } catch (error) {
      console.error("Error triggering generation:", error);
    }

    setThumbs((prevThumbs) => ({
      ...prevThumbs,
      [index]: prevThumbs[index] === type ? null : type,
    }));
  };

  const handleThumbsToggleConsideration = async (
    index,
    type,
    number,
    subNumber
  ) => {
    setLoader(true);
    try {
      const response = await getFeedback(
        params,
        "III",
        number,
        subNumber,
        type ? "True" : "False"
      );
      // Handle response if necessary
      if (response.status === "success") {
        const resp = await view2PagerLists(3, params);
        const merits = parseMeritsAndConsiderations(
          resp?.data?.bullets[0]?.sub_bullets
        );
        const consideration = parseMeritsAndConsiderations(
          resp?.data?.bullets[1]?.sub_bullets
        );
        const keyHeaders = resp?.data?.bullets[0]?.name;
        const considerationHeaders = resp?.data?.bullets[1]?.name;
        setPreliminaryData({
          merits,
          consideration,
          keyHeaders,
          considerationHeaders,
        });
        setLoader(false);
      }
    } catch (error) {
      console.error("Error triggering generation:", error);
    }
    setThumbsConsideration((prevThumbs) => ({
      ...prevThumbs,
      [index]: prevThumbs[index] === type ? null : type,
    }));
  };

  const getCimPageApi = async (twoPagerId, pageNu, value) => {
    setPageNumber(pageNu);
    setLoading(true); // Start loading
    try {
      const response = await getCimPage(twoPagerId, pageNu, value);
      setBase64(response.data.base64_format);
    } catch (response) {
      console.error("Error triggering generation:", response);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleImageClick = () => {
    setIsOpen(true);
  };

  const handleClosePopUp = (e) => {
    if (e.target.className === "popup") {
      setIsOpen(false);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      const resp = await view2PagerLists(3, params);
      setAdminEdit(resp?.edit);
      const merits = parseMeritsAndConsiderations(
        resp?.data?.bullets[0]?.sub_bullets
      );
      const consideration = parseMeritsAndConsiderations(
        resp?.data?.bullets[1]?.sub_bullets
      );
      const keyHeaders = resp?.data?.bullets[0]?.name;
      const considerationHeaders = resp?.data?.bullets[1]?.name;
      setPreliminaryData({
        merits,
        consideration,
        keyHeaders,
        considerationHeaders,
      });
      setLoader(false);
    };

    fetchData();
  }, []);

  function convertToNumbers(pageNumbers) {
    const convertedNumbers = [];

    pageNumbers?.forEach((page) => {
      const number = parseFloat(page);
      if (!isNaN(number)) {
        convertedNumbers.push(Math.floor(number));
      } else {
        console.log(`Cannot convert '${page}' to a number, skipping.`);
      }
    });

    return convertedNumbers;
  }

  function checkNumber(value) {
    let number = parseFloat(value); // Attempt to convert the string to a number (float)

    if (isNaN(number)) {
      return; // If conversion fails, return without doing anything
    }

    return Math.floor(number); // Convert to integer by truncating any decimal part
  }

  useEffect(() => {
    // console.log(preliminaryData, "preliminaryData");
  }, [preliminaryData]);

  const [mainTab, setMainTab] = useState(0);
  const [supportingDocTab, setSupportingDocTab] = useState(0);
  const [supportingDocData, setSupportingDocData] = useState([]);

  const handleClickOpen = (name, dataSource) => {
    setSelectedItemName(name);
    setOpen(true);
    setDataSource(dataSource);
    if (checkNumber(_.find(name, (item) => item?.title === "CIM")?.pageNo[0])) {
      getCimPageApi(
        params,
        _.find(name, (item) => item?.title === "CIM")?.pageNo[0],
        _.find(name, (item) => item?.title === "CIM")?.value
      );
    }
  };

  const handleSupportingDocTabChange = (event, newValue) => {
    let dataSet =
      selectedItemName?.filter((item) => item?.title !== "CIM") || [];
    setSupportingDocTab(newValue);
    setSupportingDocData(dataSet[newValue]);
    getCimPageApi(
      params,
      dataSet[newValue]?.pageNo[0],
      dataSet[newValue]?.value
    );
  };

  const handleMainTabChange = (event, newValue) => {
    setMainTab(newValue);
    if (newValue === 0) {
      getCimPageApi(
        params,
        _.find(selectedItemName, (item) => item?.title === "CIM")?.pageNo[0],
        _.find(selectedItemName, (item) => item?.title === "CIM")?.value
      );
    } else {
      setSupportingDocTab(0);
      handleSupportingDocTabChange("e", 0);
    }
  };

  const [redraftOpen, setRedraftOpen] = useState(false);
  const [dialogData, setDialogData] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [textInput, setTextInput] = useState("");
  const [reDraftData, setReDraftData] = useState({});
  const [isRedraftLoading, setRedraftLoading] = useState(false);

  const handleRedraft = (args, bullet) => {
    const updatedParam = { ...args, bullet: bullet };
    console.log(updatedParam);

    setDialogData(updatedParam);
    setRedraftOpen(true);
  };
  const handleReDraftClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setRedraftOpen(false);
      setIsChecked(false);
      setTextInput("");
      setReDraftData({});
    }
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const handleTextInputChange = (event) => {
    setTextInput(event.target.value);
  };
  const handleSubmit = async () => {
    try {
      setRedraftLoading(true);
      const formData = {
        two_pager_id: params,
        prompt: textInput,
        section: "3",
        bullet: dialogData?.bullet,
        sub_bullet: dialogData?.number,
        append: isChecked,
      };

      const response = await createReDraft(formData);

      if (response?.status === "SUCCESS") {
        setReDraftData(response);
        toast.success(response?.message);
      } else {
        toast.success(response?.message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setRedraftLoading(false);
    }
  };
  const handleAccept = async () => {
    try {
      setRedraftLoading(true);
      const parameter = {
        session_id: reDraftData?.session_id,
        accept: true,
      };
      const response = await acceptRejectRedraft(parameter);
      if (response?.status === "SUCCESS") {
        toast.success(response?.message);
        navigate(`/view-generate-2pager/${response?.two_pager_id}`);
        window.location.reload();
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error("API call failed:", error);
    } finally {
      handleReDraftClose();
      setRedraftLoading(false);
    }
  };

  const handleReject = async () => {
    try {
      const parameter = {
        session_id: reDraftData?.session_id,
        accept: false,
      };
      const response = await acceptRejectRedraft(parameter);
    } catch (error) {
      console.error("API call failed:", error);
    } finally {
      handleReDraftClose();
    }
  };

  const isSubmitEnabled = textInput.length >= 20;

  const [insightsOpen, setInsightsOpen] = useState(false);
  const [insightsData, setInsightsData] = useState("");
  const handleInsights = (item) => {
    setInsightsData(item);
    setInsightsOpen(true);
  };
  const handleInsightsClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setInsightsOpen(false);
      setInsightsData("");
    }
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [meritsToBeDeleted, setMeritsToBeDeleted] = useState([]);
  const [meritsNamesToBeDeleted, setMeritsNamesToBeDeleted] = useState([]);
  const [consToBeDeleted, setConsToBeDeleted] = useState([]);
  const [consNamesToBeDeleted, setConsNamesToBeDeleted] = useState([]);

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setMeritsToBeDeleted(null);
    setConsToBeDeleted(null);
  };

  const handleMeritsDelete = (item) => (event) => {
    if (event?.target?.checked) {
      setMeritsToBeDeleted((prev) => [...prev, item?.number]);
      setMeritsNamesToBeDeleted((prev) => [...prev, item?.name]);
    } else {
      setMeritsToBeDeleted((prev) =>
        prev?.filter((number) => number !== item?.number)
      );
      setMeritsNamesToBeDeleted((prev) =>
        prev?.filter((name) => name !== item?.name)
      );
    }
  };

  const handleConsDelete = (item) => (event) => {
    if (event?.target?.checked) {
      setConsToBeDeleted((prev) => [...prev, item?.number]);
      setConsNamesToBeDeleted((prev) => [...prev, item?.name]);
    } else {
      setConsToBeDeleted((prev) =>
        prev?.filter((number) => number !== item?.number)
      );
      setConsNamesToBeDeleted((prev) =>
        prev?.filter((name) => name !== item?.name)
      );
    }
  };

  const handleDeleteBullet = async () => {
    try {
      setIsLoading(true);
      const bulletSet =
        meritsToBeDeleted?.length > 0 && consToBeDeleted?.length === 0
          ? [
              {
                bullet_number: "1",
                sub_bullet_number: meritsToBeDeleted,
              },
            ]
          : meritsToBeDeleted?.length === 0 && consToBeDeleted?.length > 0
          ? [
              {
                bullet_number: "2",
                sub_bullet_number: consToBeDeleted,
              },
            ]
          : [
              {
                bullet_number: "1",
                sub_bullet_number: meritsToBeDeleted,
              },
              {
                bullet_number: "2",
                sub_bullet_number: consToBeDeleted,
              },
            ];

      const parameter = {
        two_pager_id: params,
        section_number: "III",
        bullet_set: bulletSet,
      };
      const response = await deleteBullets(parameter);

      if (response?.data?.status === "success") {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 5000,
        });
        setTimeout(() => {
          navigate(
            `/view-generate-2pager/${response?.data?.data?.two_pager_id}`
          );
          window.location.reload();
        }, 1000);
      } else {
        toast.error(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 5000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setOpenDeleteDialog(false);
      setMeritsToBeDeleted(null);
      setConsToBeDeleted(null);
      handleDelete(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {loader ? (
        <Loader top={"50%"} />
      ) : (
        <main
          className="markdownArea"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2,1fr)",
            gridColumnGap: "14px",
            fontSize: "14px",
            textAlign: "justify",
          }}
        >
          <section style={{ color: "#666F8D" }}>
            <Typography
              style={{ fontSize: "14px", color: "#19213D", fontWeight: "bold" }}
            >
              {preliminaryData?.keyHeaders}
            </Typography>
            <ul>
              {preliminaryData.merits &&
                preliminaryData?.merits?.map((item, index) => {
                  return (
                    <li key={index}>
                      {deleteBullet && (
                        <Checkbox
                          checked={meritsToBeDeleted?.includes(item?.number)}
                          onChange={handleMeritsDelete(item)}
                          sx={{
                            color: "#d1d4dd",
                            "&.Mui-checked": { color: "#ff4e28" },
                            padding: "5px",
                          }}
                        />
                      )}
                      <span
                        style={{ fontWeight: 500, textDecoration: "underline" }}
                      >
                        {item?.name}:
                        {section === "final2pager" ? (
                          <></>
                        ) : item?.name != null ||
                          item?.name !== "" ||
                          item?.name?.length !== 0 ? (
                          <>
                            {cimDeleted === "no" && (
                              <Tooltip
                                title="References"
                                onMouseEnter={() =>
                                  setHovered(`infoIconDescMerits${index}`)
                                }
                                onMouseLeave={() => setHovered(null)}
                              >
                                {item?.data_source === "EXTERNAL" &&
                                item?.url?.length > 0 ? (
                                  <InfoIcon
                                    onClick={() =>
                                      handleClickOpen(
                                        item?.url,
                                        item?.data_source
                                      )
                                    }
                                    style={{
                                      cursor: "pointer",
                                      opacity:
                                        hovered === `infoIconDescMerits${index}`
                                          ? 1
                                          : 0.3,
                                      transition: "opacity 0.3s",
                                      verticalAlign: "middle",
                                      marginLeft: "1%",
                                    }}
                                    className={`infoIconDescMerits${index} ${"cssClassData"}`}
                                  />
                                ) : (
                                  <></>
                                )}
                                {item?.data_source === "CIM" &&
                                item?.page?.length > 0 ? (
                                  <InfoIcon
                                    onClick={() =>
                                      handleClickOpen(
                                        item?.page,
                                        item?.data_source
                                      )
                                    }
                                    style={{
                                      cursor: "pointer",
                                      opacity: hovered === index ? 1 : 0.3,
                                      transition: "opacity 0.3s",
                                      verticalAlign: "middle",
                                      marginLeft: "1%",
                                    }}
                                    className={`infoIconDesc${index} ${"cssClassData"}`}
                                  />
                                ) : (
                                  <></>
                                )}
                              </Tooltip>
                            )}
                            {adminEdit === "yes" && (
                              <IconButton
                                onClick={() =>
                                  handleThumbsToggle(
                                    index,
                                    true,
                                    1,
                                    item?.number
                                  )
                                }
                                style={{
                                  color:
                                    item?.feedback === true
                                      ? "#7AD167"
                                      : "#0000001f",
                                }}
                              >
                                <ThumbUpIcon style={{ fontSize: "large" }} />
                              </IconButton>
                            )}
                            {adminEdit === "yes" && (
                              <IconButton
                                onClick={() =>
                                  handleThumbsToggle(
                                    index,
                                    false,
                                    1,
                                    item?.number
                                  )
                                }
                                style={{
                                  color:
                                    item?.feedback === false
                                      ? "#eb5050"
                                      : "#0000001f",
                                }}
                              >
                                <ThumbDownIcon style={{ fontSize: "large" }} />
                              </IconButton>
                            )}
                            {(redraftStatus === "yes" ||
                              adminEdit === "yes") && (
                              <Tooltip title="Redraft">
                                <RestorePageIcon
                                  sx={{
                                    cursor: "pointer",
                                    transition: "opacity 0.3s",
                                    verticalAlign: "middle",
                                    marginLeft: "1%",
                                    fontSize: "large",
                                    opacity: item?.redrafted ? 1 : 0.3,
                                    color: item?.redrafted
                                      ? "green"
                                      : "#666f8d",
                                    "&:hover": {
                                      opacity: 1,
                                    },
                                  }}
                                  onClick={() => handleRedraft(item, "1")}
                                />
                              </Tooltip>
                            )}
                            {item?.insights?.page_no?.length > 0 && (
                              <Tooltip title="Insights">
                                <LightbulbIcon
                                  sx={{
                                    cursor: "pointer",
                                    opacity: 0.3,
                                    transition: "opacity 0.3s",
                                    verticalAlign: "middle",
                                    marginLeft: "1%",
                                    fontSize: "large",
                                    "&:hover": {
                                      opacity: 1,
                                    },
                                  }}
                                  onClick={() => handleInsights(item)}
                                />
                              </Tooltip>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </span>
                      <ReactMarkdown
                        children={item?.description}
                        components={md}
                      />
                    </li>
                  );
                })}
            </ul>
          </section>
          <section style={{ color: "#666F8D" }}>
            <Typography
              style={{ fontSize: "14px", color: "#19213D", fontWeight: "bold" }}
            >
              {preliminaryData?.considerationHeaders}
            </Typography>
            <ul>
              {preliminaryData.consideration &&
                preliminaryData.consideration?.map((item, index) => {
                  return (
                    <li key={index}>
                      {deleteBullet && (
                        <Checkbox
                          checked={consToBeDeleted?.includes(item?.number)}
                          onChange={handleConsDelete(item)}
                          sx={{
                            color: "#d1d4dd",
                            "&.Mui-checked": { color: "#ff4e28" },
                            padding: "5px",
                          }}
                        />
                      )}
                      <span
                        style={{ fontWeight: 500, textDecoration: "underline" }}
                      >
                        {item?.name}:
                        {section === "final2pager" ? (
                          <></>
                        ) : item?.name != null ||
                          item?.name !== "" ||
                          item?.name?.length !== 0 ? (
                          <>
                            {cimDeleted === "no" && (
                              <Tooltip
                                title="References"
                                onMouseEnter={() =>
                                  setHovered(
                                    `infoIconDescConsideration${index}`
                                  )
                                }
                                onMouseLeave={() => setHovered(null)}
                              >
                                {item?.data_source === "EXTERNAL" &&
                                  item?.url?.length > 0 && (
                                    <InfoIcon
                                      onClick={() =>
                                        handleClickOpen(
                                          item?.url,
                                          item?.data_source
                                        )
                                      }
                                      style={{
                                        cursor: "pointer",
                                        opacity:
                                          hovered ===
                                          `infoIconDescMerits${index}`
                                            ? 1
                                            : 0.3,
                                        transition: "opacity 0.3s",
                                        verticalAlign: "middle",
                                        marginLeft: "1%",
                                      }}
                                      className={`infoIconDescMerits${index} ${"cssClassData"}`}
                                    />
                                  )}
                                {item?.data_source === "CIM" &&
                                  item?.page?.length > 0 && (
                                    <InfoIcon
                                      onClick={() =>
                                        handleClickOpen(
                                          item?.page,
                                          item?.data_source
                                        )
                                      }
                                      style={{
                                        cursor: "pointer",
                                        opacity: hovered === index ? 1 : 0.3,
                                        transition: "opacity 0.3s",
                                        verticalAlign: "middle",
                                        marginLeft: "1%",
                                      }}
                                      className={`infoIconDescConsideration${index} ${"cssClassData"}`}
                                    />
                                  )}
                              </Tooltip>
                            )}
                            {adminEdit === "yes" && (
                              <IconButton
                                onClick={() =>
                                  handleThumbsToggleConsideration(
                                    index,
                                    true,
                                    2,
                                    item?.number
                                  )
                                }
                                style={{
                                  color:
                                    item?.feedback === true
                                      ? "#7AD167"
                                      : "#0000001f",
                                }}
                              >
                                <ThumbUpIcon style={{ fontSize: "large" }} />
                              </IconButton>
                            )}
                            {adminEdit === "yes" && (
                              <IconButton
                                onClick={() =>
                                  handleThumbsToggleConsideration(
                                    index,
                                    false,
                                    2,
                                    item?.number
                                  )
                                }
                                style={{
                                  color:
                                    item?.feedback === false
                                      ? "#eb5050"
                                      : "#0000001f",
                                }}
                              >
                                <ThumbDownIcon style={{ fontSize: "large" }} />
                              </IconButton>
                            )}
                            {(redraftStatus === "yes" ||
                              adminEdit === "yes") && (
                              <Tooltip title="Redraft">
                                <RestorePageIcon
                                  sx={{
                                    cursor: "pointer",
                                    transition: "opacity 0.3s",
                                    verticalAlign: "middle",
                                    marginLeft: "1%",
                                    fontSize: "large",
                                    opacity: item?.redrafted ? 1 : 0.3,
                                    color: item?.redrafted
                                      ? "green"
                                      : "#666f8d",
                                    "&:hover": {
                                      opacity: 1,
                                    },
                                  }}
                                  onClick={() => handleRedraft(item, "2")}
                                />
                              </Tooltip>
                            )}
                            {item?.insights?.page_no?.length > 0 && (
                              <Tooltip title="Insights">
                                <LightbulbIcon
                                  sx={{
                                    cursor: "pointer",
                                    opacity: 0.3,
                                    transition: "opacity 0.3s",
                                    verticalAlign: "middle",
                                    marginLeft: "1%",
                                    fontSize: "large",
                                    "&:hover": {
                                      opacity: 1,
                                    },
                                  }}
                                  onClick={() => handleInsights(item)}
                                />
                              </Tooltip>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </span>
                      <ReactMarkdown
                        children={item?.description}
                        components={md}
                      />
                    </li>
                  );
                })}
            </ul>
          </section>

          <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <Grid
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                margin: "1%",
                cursor: "pointer",
              }}
            >
              <HighlightOffIcon onClick={handleClose} />
            </Grid>
            {/* <DialogTitle>Information {selectedItemName}</DialogTitle> */}
            <DialogContent>
              <Box sx={{ display: "flex" }}>
                {dataSource === "EXTERNAL" ? (
                  <Tabs
                    orientation="vertical"
                    value={mainTab}
                    onChange={handleMainTabChange}
                    sx={{ borderRight: 1, borderColor: "divider" }}
                  >
                    <Tab
                      label="References"
                      sx={{ alignItems: "end", textTransform: "capitalize" }}
                    />
                  </Tabs>
                ) : (
                  <Tabs
                    orientation="vertical"
                    value={mainTab}
                    onChange={handleMainTabChange}
                    sx={{ borderRight: 1, borderColor: "divider" }}
                  >
                    <Tab label="CIM" sx={{ alignItems: "end" }} />
                    <Tab
                      label="Supporting Doc(s)"
                      sx={{ alignItems: "end", textTransform: "none" }}
                    />
                  </Tabs>
                )}

                <Box sx={{ flexGrow: 1, p: 3 }}>
                  {/* CIM Tab Content */}
                  {mainTab === 0 && (
                    <Box
                      sx={{
                        flexGrow: 1,
                        bgcolor: "background.paper",
                        display: "flex",
                        height: 224,
                      }}
                    >
                      {_.find(
                        selectedItemName,
                        (item) => item?.title === "CIM"
                      ) && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <Grid
                            className="pageNumber"
                            style={{
                              maxWidth: "460px",
                              wordWrap: "break-word",
                              whiteSpace: "pre-wrap",
                              flexFlow: "wrap",
                              fontSize: "14px",
                              marginBottom: "10px",
                            }}
                          >
                            <p style={{ margin: "0%", fontWeight: "bold" }}>
                              Page Number(s) :
                            </p>

                            <>
                              {convertToNumbers(
                                _.find(
                                  selectedItemName,
                                  (item) => item?.title === "CIM"
                                ).pageNo
                              )?.map((item, index) => (
                                <div
                                  key={index}
                                  className="circular-button"
                                  onClick={() =>
                                    getCimPageApi(
                                      params,
                                      item,
                                      _.find(
                                        selectedItemName,
                                        (item) => item?.title === "CIM"
                                      ).value
                                    )
                                  }
                                >
                                  {item}
                                </div>
                              ))}
                            </>
                          </Grid>

                          <div style={{ marginTop: "10px" }}>
                            {pageNumber ? (
                              <>
                                <div>
                                  {loading ? (
                                    <Loader top={"50%"} />
                                  ) : (
                                    <>
                                      <img
                                        src={"data:image/png;base64," + base64}
                                        alt="Selected"
                                        style={{
                                          width: "370px",
                                          cursor: "pointer",
                                        }}
                                        onClick={handleImageClick}
                                      />
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          fontSize: "14px",
                                        }}
                                      >
                                        <div>Click on image to enlarge</div>
                                        <div>page {pageNumber}</div>
                                      </div>
                                    </>
                                  )}
                                </div>

                                {isOpen && (
                                  <div
                                    className="popup"
                                    onClick={handleClosePopUp}
                                  >
                                    <div className="popup-content">
                                      <div className="scrollable-content">
                                        <img
                                          src={
                                            "data:image/png;base64," + base64
                                          }
                                          alt="Selected"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      )}

                      {dataSource === "EXTERNAL" && (
                        <>
                          <Grid
                            className="pageNumber"
                            style={{
                              maxWidth: "460px",
                              wordWrap: "break-word",
                              whiteSpace: "pre-wrap",
                              flexFlow: "wrap",
                              fontSize: "14px",
                              marginBottom: "10px",
                            }}
                          >
                            <p style={{ margin: "0%", fontWeight: "bold" }}>
                              URL(s) :
                            </p>
                            {selectedItemName?.map((item, index) => (
                              <ul style={{ margin: "0%" }}>
                                <li key={index}>
                                  <div>
                                    <a
                                      href="#"
                                      onClick={() =>
                                        window.open(item, "_blank")
                                      }
                                    >
                                      {item}
                                    </a>
                                  </div>
                                </li>
                              </ul>
                            ))}
                          </Grid>
                        </>
                      )}
                    </Box>
                  )}

                  {mainTab === 1 &&
                    (selectedItemName?.filter((item) => item?.title !== "CIM")
                      ?.length > 0 ? (
                      <Box>
                        <AppBar position="static" color="default">
                          <Tabs
                            value={supportingDocTab}
                            onChange={handleSupportingDocTabChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="Supporting Documents Tabs"
                          >
                            {selectedItemName
                              ?.filter((item) => item?.title !== "CIM")
                              ?.map((i, index) => (
                                <Tab
                                  key={index}
                                  label={`Supporting Document ${index + 1}`}
                                  sx={{ textTransform: "capitalize" }}
                                />
                              ))}
                          </Tabs>
                        </AppBar>
                        <Box sx={{ p: 3 }}>
                          {supportingDocData ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                              }}
                            >
                              <Grid
                                className="pageNumber"
                                style={{
                                  maxWidth: "460px",
                                  wordWrap: "break-word",
                                  whiteSpace: "pre-wrap",
                                  flexFlow: "wrap",
                                  fontSize: "14px",
                                  marginBottom: "10px",
                                }}
                              >
                                <p
                                  style={{
                                    margin: "0%",
                                    fontWeight: "bold",
                                    marginBottom: 20,
                                    width: "100%",
                                  }}
                                >
                                  File Name :{" "}
                                  <p
                                    style={{
                                      fontWeight: 500,
                                      display: "inline",
                                    }}
                                  >
                                    {supportingDocData?.title}
                                  </p>
                                </p>

                                <p
                                  style={{
                                    marginTop: "0%",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Page Number(s) :
                                </p>

                                <>
                                  {convertToNumbers(
                                    supportingDocData?.pageNo
                                  )?.map((item, index) => (
                                    <div
                                      key={index}
                                      className="circular-button"
                                      onClick={() =>
                                        getCimPageApi(
                                          params,
                                          item,
                                          supportingDocData?.value
                                        )
                                      }
                                    >
                                      {item}
                                    </div>
                                  ))}
                                </>
                              </Grid>

                              <div style={{ marginTop: "10px" }}>
                                {pageNumber ? (
                                  <>
                                    <div>
                                      {loading ? (
                                        <Loader top={"50%"} />
                                      ) : (
                                        <>
                                          <img
                                            src={
                                              "data:image/png;base64," + base64
                                            }
                                            alt="Selected"
                                            style={{
                                              width: "370px",
                                              cursor: "pointer",
                                            }}
                                            onClick={handleImageClick}
                                          />
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              fontSize: "14px",
                                            }}
                                          >
                                            <div>Click on image to enlarge</div>
                                            <div>page {pageNumber}</div>
                                          </div>
                                        </>
                                      )}
                                    </div>

                                    {isOpen && (
                                      <div
                                        className="popup"
                                        onClick={handleClosePopUp}
                                      >
                                        <div className="popup-content">
                                          <div className="scrollable-content">
                                            <img
                                              src={
                                                "data:image/png;base64," +
                                                base64
                                              }
                                              alt="Selected"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          ) : (
                            "No data available."
                          )}
                        </Box>
                      </Box>
                    ) : (
                      <p>{"No data available."}</p>
                    ))}
                </Box>
              </Box>
            </DialogContent>

            <DialogActions></DialogActions>
          </Dialog>

          <Dialog
            open={redraftOpen}
            onClose={handleReDraftClose}
            fullWidth
            maxWidth="md"
          >
            <DialogTitle
              sx={{
                borderBottom: "1px solid #9e9e9e",
                marginBottom: "15px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  {"Redraft"} &nbsp;
                  {/* <Typography
                    component="span"
                    sx={{
                      color: "green",
                      fontSize: "0.75rem",
                      backgroundColor: "#E0F7FA",
                      padding: "0 4px",
                      borderRadius: "4px",
                      display: "inline-block",
                    }}
                  >
                    beta
                  </Typography> */}
                </Box>
                <HighlightOffIcon
                  onClick={handleReDraftClose}
                  sx={{
                    cursor: "pointer",
                    color: "grey.500",
                  }}
                />
              </Box>
            </DialogTitle>
            <DialogContent sx={{ padding: "0 24px" }}>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    mb: 2,
                  }}
                >
                  <Tooltip
                    title={
                      <Box>
                        <Box display="flex" alignItems="flex-start">
                          <CheckBoxOutlinedIcon
                            fontSize="small"
                            sx={{ mr: 0.5 }}
                          />
                          <Typography
                            variant="caption"
                            sx={{ fontSize: "0.8rem" }}
                          >
                            Check this box to add new content to the existing
                            Rainmaker output, keeping the original context.
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="flex-start">
                          <CheckBoxOutlineBlankOutlinedIcon
                            fontSize="small"
                            sx={{ mr: 0.5 }}
                          />
                          <Typography
                            variant="caption"
                            sx={{ fontSize: "0.8rem" }}
                          >
                            If unchecked, a fresh output is generated without
                            the existing context.
                          </Typography>
                        </Box>
                      </Box>
                    }
                  >
                    <InfoOutlinedIcon sx={{ cursor: "pointer", mr: 1 }} />
                  </Tooltip>
                  <Typography variant="body1" sx={{ mr: 1, fontSize: 14 }}>
                    {"Append to the Rainmaker output"}
                  </Typography>
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    sx={{
                      color: "#7AD167",
                      "&.Mui-checked": { color: "#7AD167" },
                    }}
                  />
                </Box>

                <Box sx={{ mb: 2 }}>
                  <TextField
                    label="Please provide your input prompt"
                    variant="filled"
                    size="small"
                    fullWidth
                    multiline
                    rows={4}
                    value={textInput}
                    onChange={handleTextInputChange}
                    InputProps={{
                      sx: { fontSize: "0.875rem" },
                    }}
                  />
                  {textInput.length > 0 && textInput.length < 20 && (
                    <Typography
                      variant="body2"
                      color="warning.main"
                      sx={{ mt: 1, fontSize: 12 }}
                    >
                      Please enter at least 20 characters.
                    </Typography>
                  )}
                </Box>

                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<SendOutlinedIcon />}
                    onClick={handleSubmit}
                    sx={{
                      textTransform: "capitalize",
                      backgroundColor: "#7AD167",
                      boxShadow:
                        "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                      "&:hover": {
                        backgroundColor: "#7AD167",
                        boxShadow:
                          "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);",
                      },
                    }}
                    disabled={!isSubmitEnabled}
                  >
                    Submit
                  </Button>
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    mb: "10px",
                    fontSize: 16,
                    color: "#292f62",
                    fontWeight: 600,
                  }}
                >
                  {"Rainmaker output :"}
                </Typography>
                <section
                  style={{
                    color: "#666F8D",
                    fontSize: "14px",
                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{ fontWeight: 500, textDecoration: "underline" }}
                  >
                    {dialogData?.name}:
                  </span>
                  <ul>
                    <li>
                      <ReactMarkdown
                        children={dialogData?.description}
                        components={md}
                      />
                    </li>
                  </ul>
                </section>
                {reDraftData?.value && (
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        mb: 2,
                        fontSize: 16,
                        color: "#292f62",
                        fontWeight: 600,
                      }}
                    >
                      {"Redrafted response :"}
                    </Typography>

                    <section
                      style={{
                        color: "#666F8D",
                        fontSize: "14px",
                        textAlign: "justify",
                      }}
                    >
                      <span
                        style={{ fontWeight: 500, textDecoration: "underline" }}
                      >
                        {dialogData?.name}:
                      </span>

                      <ul>
                        <li>
                          <ReactMarkdown
                            children={reDraftData?.value}
                            components={md}
                          />
                        </li>
                      </ul>
                    </section>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<CheckIcon />}
                        sx={{
                          textTransform: "capitalize",
                          backgroundColor: "#7AD167",
                          boxShadow:
                            "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                          "&:hover": {
                            backgroundColor: "#7AD167",
                            boxShadow:
                              "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);",
                          },
                          marginRight: 2,
                        }}
                        onClick={handleAccept}
                      >
                        Accept
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        startIcon={<CloseIcon />}
                        sx={{
                          textTransform: "capitalize",
                          backgroundColor: "#F44336",
                          boxShadow:
                            "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                          "&:hover": {
                            backgroundColor: "#F44336",
                            boxShadow:
                              "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);",
                          },
                        }}
                        onClick={handleReject}
                      >
                        Reject
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            </DialogContent>

            <DialogActions></DialogActions>
          </Dialog>
          <Dialog
            open={insightsOpen}
            onClose={handleInsightsClose}
            fullWidth
            maxWidth="md"
          >
            <DialogTitle
              sx={{
                borderBottom: "1px solid #9e9e9e",
                marginBottom: "15px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  {"Insights"} &nbsp;
                  {/* <Typography
                    component="span"
                    sx={{
                      color: "green",
                      fontSize: "0.75rem",
                      backgroundColor: "#E0F7FA",
                      padding: "0 4px",
                      borderRadius: "4px",
                      display: "inline-block",
                    }}
                  >
                    beta
                  </Typography> */}
                </Box>
                <HighlightOffIcon
                  onClick={handleInsightsClose}
                  sx={{
                    cursor: "pointer",
                    color: "grey.500",
                  }}
                />
              </Box>
            </DialogTitle>
            <DialogContent sx={{ padding: "24px" }}>
              {insightsData !== null ? (
                <section
                  style={{
                    color: "#666F8D",
                    fontSize: "14px",
                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{ fontWeight: 500, textDecoration: "underline" }}
                  >
                    {insightsData?.name}:
                  </span>

                  <ReactMarkdown
                    children={insightsData?.insights?.result}
                    components={md}
                  />
                </section>
              ) : (
                "This feature analyzes uploaded expert call notes to extract relevant insights. It checks if data is available concerning the bullet's context and retrieves the expert's perspectives and insights on the company accordingly."
              )}
            </DialogContent>

            <DialogActions></DialogActions>
          </Dialog>
          {isRedraftLoading && (
            <Box
              className="reDraftloader"
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 2000,
              }}
            >
              <AutoAwesomeIcon
                color="inherit"
                fontSize="large"
                sx={{ animation: "zoomBlink 2s infinite ease-in-out" }}
              />
            </Box>
          )}
          <ToastContainer />
        </main>
      )}

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Bullet(s)</DialogTitle>
        <DialogContent>
          Are you sure you want to delete following bullet(s)?
          <br />
          <br />
          {meritsNamesToBeDeleted?.length > 0 && (
            <>
              <Typography style={{ fontWeight: "bold" }}>{"Merits"}</Typography>
              <ol style={{ paddingLeft: "20px", listStyleType: "decimal" }}>
                {meritsNamesToBeDeleted?.length > 0 &&
                  meritsNamesToBeDeleted.map((item, index) => (
                    <li key={index} style={{ marginBottom: "8px" }}>
                      <Typography
                        variant="body2"
                        sx={{ fontSize: "0.875rem", margin: 0 }}
                      >
                        {item}
                      </Typography>
                    </li>
                  ))}
              </ol>
            </>
          )}
          {consNamesToBeDeleted?.length > 0 && (
            <>
              {"Considerations"}
              <ol style={{ paddingLeft: "20px", listStyleType: "decimal" }}>
                {consNamesToBeDeleted.map((item, index) => (
                  <li key={index} style={{ marginBottom: "8px" }}>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "0.875rem", margin: 0 }}
                    >
                      {item}
                    </Typography>
                  </li>
                ))}
              </ol>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteBullet} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {(meritsToBeDeleted?.length > 0 || consToBeDeleted?.length > 0) && (
        <Button
          variant="contained"
          style={{
            textTransform: "none",
            height: "28px",
            float: "right",
            backgroundColor: "rgb(255, 78, 40)",
            color: "#ffffff",
            position: "absolute",
            top: "34%",
            right: "4%",
          }}
          onClick={() => handleOpenDeleteDialog()}
        >
          Delete
        </Button>
      )}

      {isLoading && (
        <Box
          className="loaderPage"
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 2000,
          }}
        >
          <RotateRightIcon
            color="inherit"
            fontSize="large"
            sx={{ animation: "spin 2s infinite ease-in-out" }}
          />
        </Box>
      )}
    </>
  );
};

export default KeyMeritsAndConsideration;
