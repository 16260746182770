import React, { useEffect, Suspense } from "react";
import { useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import Routes from "./Routes";
import "./App.css";
import { MSAL_CONFIG } from "./azure/authConfig";
import { store } from "./Components/redux/config/store";

import { PublicClientApplication } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import SignIn from "./Components/Login";
import veritasCapitalLogo from "./Assets/Icons/veritas-capital-logo.svg";
import Favicon from "react-favicon";

const msalInstance = new PublicClientApplication(MSAL_CONFIG);

function App() {
  useEffect(() => {
    document.title = "Rainmaker AI";
  }, []);

  return (
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <AuthenticatedTemplate>
          <BrowserRouter>
            <Suspense fallback={<div>Loading...</div>}>
              <Favicon url={veritasCapitalLogo} />
              <div className="App">
                <Routes />
              </div>
            </Suspense>
          </BrowserRouter>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <SignIn />
        </UnauthenticatedTemplate>
      </MsalProvider>
    </Provider>
  );
}

export default App;
