import React, { useState, useEffect } from "react";
import {
  Box,
  Tab,
  Tabs,
  DialogActions,
  DialogContent,
  Dialog,
  Tooltip,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useParams } from "react-router-dom";
import { getCimPage, getFeedback, view2PagerLists } from "../service";
import ReactMarkdown from "react-markdown";
import MarkdownIt from "markdown-it";
import "../index.css";
import Loader from "../../common/Loader";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";

const md = new MarkdownIt();
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const OtherRelevantInfo = ({ data, section, cimDeleted }) => {
  const urlParams = useParams();
  const params = urlParams.userId;
  const [open, setOpen] = useState(false);
  const [hovered, setHovered] = useState(null);
  const [subHovered, setSubHovered] = useState(null);
  const [selectedItemName, setSelectedItemName] = useState([]);
  const [value, setValue] = React.useState(0);
  const [base64, setBase64] = useState("");
  const [pageNumber, setPageNumber] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [thumbs, setThumbs] = useState({});
  const [otherRelData, setOtherRelData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [adminEdit, setAdminEdit] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = (name) => {
    setSelectedItemName(name);
    setOpen(true);
  };

  const handleThumbsToggle = async (index, type, number, subNumber) => {
    setLoader(true);
    try {
      const response = await getFeedback(
        params,
        "X",
        number,
        subNumber,
        type ? "True" : "False"
      );
      // Handle response if necessary
      if (response.status === "success") {
       
        const response = await view2PagerLists(10, params);
        const resultArray = response.data?.bullets.map((bullet) => ({
          title: bullet.name,
          subtitle: bullet.values[0].result,
          url: bullet.values[0].url,
          number: bullet.number,
          feedback: bullet.feedback,
        }));
        setOtherRelData(resultArray);
        setLoader(false);
      }
    } catch (error) {
      console.error("Error triggering generation:", error);
    }

    setThumbs((prevThumbs) => ({
      ...prevThumbs,
      [index]: prevThumbs[index] === type ? null : type,
    }));
  };
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
      setSelectedItemName("");
      setPageNumber("");
    }
  };

  const getCimPageApi = async (twoPagerId, pageNu) => {
    setPageNumber(pageNu);
    setLoading(true); // Start loading
    try {
      const response = await getCimPage(twoPagerId, pageNu);
      setBase64(response.data.base64_format);
    } catch (response) {
      console.error("Error triggering generation:", response);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleImageClick = () => {
    setIsOpen(true);
  };

  const handleClosePopUp = (e) => {
    if (e.target.className === "popup") {
      setIsOpen(false);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      const response = await view2PagerLists(10, params);
      setAdminEdit(response?.edit);
      const resultArray = response.data?.bullets.map((bullet) => ({
        title: bullet.name,
        subtitle: bullet.values[0].result,
        url: bullet.values[0].url,
        number: bullet.number,
        feedback: bullet.feedback,
      }));
      setOtherRelData(resultArray);
      setLoader(false);
    };

    fetchData();
  }, []);

  useEffect(() => {}, [otherRelData]);

  return (
    <>
      {loader ? (
        <Loader top={"50%"} />
      ) : (
        <main className="markdownArea">
          {otherRelData.length > 0 &&
            otherRelData.map((item, key) => (
              <section
                style={{
                  color: "#666F8D",
                  fontSize: "14px",
                  textAlign: "justify",
                }}
              >
                <React.Fragment key={key}>
                  <span
                   
                    style={{ fontWeight: 500, textDecoration: "underline" }}
                  >
                    {item?.title}:
                    {section === "final2pager" ? (
                      <></>
                    ) : item?.url != null &&
                      item?.url !== "" &&
                      item?.url?.length > 0 ? (
                      <>
                        {cimDeleted === "no" && (
                          <Tooltip title="References"
                          onMouseEnter={() => setHovered(key)}
                          onMouseLeave={() => setHovered(null)}
                          >
                            <InfoIcon
                              onClick={() => handleClickOpen(item?.url)}
                              style={{
                                cursor: "pointer",
                                opacity: hovered === key ? 1 : 0.3,
                                transition: "opacity 0.3s",
                                verticalAlign: "middle",
                                marginLeft: "1%",
                              }}
                              className={`infoIconDesc${key} ${"cssClassData"}`}
                            />
                          </Tooltip>
                        )}
                        {adminEdit === "yes" && (
                          <IconButton
                            onClick={() =>
                              handleThumbsToggle(key, true, item?.number, null)
                            }
                            style={{
                              color:
                                item?.feedback === true
                                  ? "#7AD167"
                                  : "#0000001f",
                            }}
                          >
                            <ThumbUpIcon style={{ fontSize: "large" }} />
                          </IconButton>
                        )}
                        {adminEdit === "yes" && (
                          <IconButton
                            onClick={() =>
                              handleThumbsToggle(key, false, item?.number, null)
                            }
                            style={{
                              color:
                                item?.feedback === false
                                  ? "#eb5050"
                                  : "#0000001f",
                            }}
                          >
                            <ThumbDownIcon style={{ fontSize: "large" }} />
                          </IconButton>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </span>
                  {item?.subtitle && (
                    <ReactMarkdown children={item?.subtitle} components={md} />
                  )}
                </React.Fragment>
              </section>
            ))}
          <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                height: "500px",
                width: "700px",
              },
            }}
          >
            <Grid
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                margin: "1%",
              }}
            >
              <HighlightOffIcon onClick={handleClose} />
            </Grid>
            {/* <DialogTitle>Information {selectedItemName}</DialogTitle> */}
            <DialogContent>
              <Box
                sx={{
                  flexGrow: 1,
                  bgcolor: "background.paper",
                  display: "flex",
                  height: 224,
                }}
              >
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{
                    borderRight: 1,
                    borderColor: "divider",
                    "& .Mui-selected": {
                      color: "#292f62",
                      fontWeight: 700,
                    },
                  }}
                >
                  <Tab
                    label="References "
                    {...a11yProps(0)}
                    style={{ fontWeight: 700 }}
                  />
                  {/* <Tab label="Explain ability" {...a11yProps(1)} /> */}
                </Tabs>
                <TabPanel value={value} index={0}>
                  <Grid
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      textWrap: "nowrap",
                    }}
                  >
                    <p style={{ fontWeight: "bold" }}>Source : </p>
                    <p style={{ marginLeft: "1%" }}> External Data</p>
                  </Grid>
                  {selectedItemName ? (
                    <Grid
                      className="pageNumber"
                      style={{
                        maxWidth: "460px",
                        wordWrap: "break-word",
                        whiteSpace: "pre-wrap",
                        flexFlow: "wrap",
                        fontSize: "14px",
                        marginBottom: "10px",
                      }}
                    >
                      <p style={{ margin: "0%", fontWeight: "bold" }}>
                        URL(s) :
                      </p>
                      {selectedItemName?.map((item, index) => (
                        <ul>
                          <li key={index} style={{ width: "320px" }}>
                            <div>
                              <a
                                href="#"
                                onClick={() => window.open(item, "_blank")}
                              >
                                {item}
                              </a>
                            </div>
                          </li>
                        </ul>
                      ))}
                    </Grid>
                  ) : (
                    <></>
                  )}
                </TabPanel>
                {/* <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
      <TabPanel value={value} index={3}>
        Item Four
      </TabPanel> */}
              </Box>
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
        </main>
      )}
    </>
  );
};

export default OtherRelevantInfo;
