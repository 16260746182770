import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { toast } from "react-toastify";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useNavigate, useLocation } from "react-router-dom";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import { submitUser, fetchUsers } from "../service";

const AddUserForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [role, setRole] = useState("");
  const [status, setStatus] = useState("");

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await submitUser(userId, userName, role, status);
      if (response?.status === "success") {
        toast.success(response?.message, {
          position: "top-right",
          autoClose: 5000,
        });
      }
      navigate("/admin/user-management");
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getUser = async (userID) => {
    try {
      setIsLoading(true);
      const response = await fetchUsers(userID);
      if (response?.status === "success") {
        setUserName(response?.data[0]?.user_name);
        setUserEmail(response?.data[0]?.email_id);
        setRole(response?.data[0]?.user_role);
        setStatus(response?.data[0]?.status);
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  useEffect(() => {
    if (location?.state !== null) {
      getUser(location?.state?.user_id);
      setUserId(location?.state?.user_id);
    }
  }, [location]);

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="8px"
      >
        <Typography variant="h6" gutterBottom>
          Manage User Details
        </Typography>
        <Typography
          variant="h7"
          gutterBottom
          onClick={() => navigate("/admin/user-management")}
          sx={{
            display: "inline-flex",
            alignItems: "center",
            cursor: "pointer",
            border: "1px solid",
            borderColor: "primary.main",
            borderRadius: "4px",
            padding: "4px 8px",
          }}
        >
          <ArrowBackIosNewOutlinedIcon
            sx={{ marginRight: "4px", fontSize: "12px" }}
          />
          Back
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="User Name"
            placeholder="Enter User Name"
            variant="outlined"
            fullWidth
            margin="dense"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            className="compact-input"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Email Id"
            placeholder="Enter Email Id"
            variant="outlined"
            fullWidth
            margin="dense"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            className="compact-input"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="dense" className="compact-input">
            <InputLabel>Role</InputLabel>
            <Select
              label="Role"
              value={role}
              onChange={handleRoleChange}
              fullWidth
            >
              <MenuItem value="User">User</MenuItem>
              <MenuItem value="Admin">Admin</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="dense" className="compact-input">
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              value={status}
              onChange={handleStatusChange}
              fullWidth
            >
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Disabled">Disabled</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isLoading || !userName || !userEmail || !role || !status}
        >
          Submit
        </Button>
      </Box>
      {isLoading && (
        <Box
          className="loaderPage"
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 2000,
          }}
        >
          <RotateRightIcon
            color="inherit"
            fontSize="large"
            sx={{ animation: "spin 2s infinite ease-in-out" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default AddUserForm;
