import axios from "axios";
import { environment } from "../../environment";

const buildUrl = (path) => {
  return environment.baseUrl + path;
};

const reDraftbuildUrl = (path) => {
  return environment.reDraftBaseUrl + path;
};

const getUserDetails = () => {
  const response = JSON.parse(localStorage.getItem("userLoginInfo"));
  const data = {
    email_id: response.userEmail,
    access_token: response.access_token,
  };

  const options = {
    headers: data,
  };
  return options;
};

const getCimFileUpload = async (options) => {
  const { data } = await axios.post(
    buildUrl("cim_api/cim/upload"),
    options,
    getUserDetails()
  );
  return data;
};

const view2PagerLists = async (section_no, cim_id) => {
  const userHeaders = getUserDetails();
  const { data } = await axios.post(
    buildUrl(
      `2pager/view_v2/get_section?two_pager_id=${cim_id}&section_no=${section_no}&output_type=json`
    ),
    null,
    userHeaders
  );
  return data;
};

const view2GenerationStatus = async (section_no, reportId, expertReportId) => {
  const userHeaders = getUserDetails();
  const { data } = await axios.post(
    buildUrl(
      `2pager/generation/status?two_pager_id=${section_no}&report_id=${reportId}&expert_call_note_id=${expertReportId}&output_type=json`
    ),
    null,
    userHeaders
  );
  return data;
};

const view2GenerationTrigger = async (
  newCimId,
  companyName,
  documentName,
  dealCloud,
  reportId,
  expertReportId,
  parameter,
  isExternalData
) => {
  const userHeaders = getUserDetails();
  const { data } = await axios.post(
    buildUrl(`2pager/generation/trigger?cim_id=${newCimId}&company_name=${encodeURIComponent(
      companyName
    )}&document_name=${encodeURIComponent(documentName)}
        &deal_cloud_id=${dealCloud}&report_id=${reportId}&expert_call_note_id=${expertReportId}&external_source=${
      isExternalData === true ? "True" : "False"
    }`),
    parameter,
    userHeaders
  );
  return data;
};
const generationCancel = async (section_no) => {
  const userHeaders = getUserDetails();
  const { data } = await axios.post(
    buildUrl(
      `2pager/generation/cancel?two_pager_id=${section_no}&output_type=json`
    ),
    null,
    userHeaders
  );
  return data;
};
const fetchPopulateData = async (deal_name) => {
  const userHeaders = getUserDetails();
  const { data } = await axios.post(
    buildUrl(
      `cim_api/cim/populate_data?deal_cloud_id=${deal_name}&output_type=json`
    ),
    null,
    userHeaders
  );
  return data;
};

const getCimPage = async (twoPagerId, pageNu, val) => {
  const userHeaders = getUserDetails();
  const { data } = await axios.get(
    buildUrl(
      `2pager/view_v2/get_cim_page?two_pager_id=${twoPagerId}&page_number=${pageNu}&item_id=${val}&output_type=json`
    ),
    null,
    userHeaders
  );
  return data;
};

const editAccept = async (parammeters) => {
  const userHeaders = getUserDetails();
  const { data } = await axios.post(
    buildUrl(`2pager/view_v2/draft_version`),
    parammeters,
    userHeaders
  );
  return data;
};

const editAcceptAll = async (parammeters) => {
  const userHeaders = getUserDetails();
  const { data } = await axios.post(
    buildUrl(`2pager/view_v2/version_change`),
    parammeters,
    userHeaders
  );
  return data;
};

const getFeedback = async (
  twoPagerId,
  sectio_no,
  bullet_no,
  sub_bullet_no,
  feedback
) => {
  const userHeaders = getUserDetails();
  const { data } = await axios.get(
    buildUrl(
      `2pager/view_v2/feedback?two_pager_id=${twoPagerId}&section_number=${sectio_no}&bullet_number=${bullet_no}&sub_bullet_number=${sub_bullet_no}&feedback=${feedback}`
    ),
    userHeaders
  );
  return data;
};

const getAllVersions = async (twoPagerId) => {
  const userHeaders = getUserDetails();
  const { data } = await axios.post(
    buildUrl(`2pager/view_v2/get_all_versions?two_pager_id=${twoPagerId}`),
    null,
    userHeaders
  );
  return data;
};

const rePopulateData = async (twoPagerId) => {
  const userHeaders = getUserDetails();
  const { data } = await axios.post(
    buildUrl(
      `cim_api/cim/repopulate_data?two_pager_id=${twoPagerId}&output_type=json`
    ),
    null,
    userHeaders
  );
  return data;
};

const deleteUplodedFiles = async (parammeters, status) => {
  const userHeaders = getUserDetails();
  const { data } = await axios.post(
    buildUrl(`cim_api/cim/delete_report?report=${status}`),
    parammeters,
    userHeaders
  );
  return data;
};
const acceptRejectRedraft = async (parammeters) => {
  const userHeaders = getUserDetails();
  const { data } = await axios.post(
    reDraftbuildUrl(`regen_api/regeneration/accept_bullet`),
    parammeters,
    userHeaders
  );
  return data;
};
const createReDraft = async (parammeters) => {
  const userHeaders = getUserDetails();
  const { data } = await axios.post(
    reDraftbuildUrl(`regen_api/regeneration/regenerate_bullet`),
    parammeters,
    userHeaders
  );
  return data;
};
const createReDraftV2 = async (parammeters) => {
  const userHeaders = getUserDetails();
  const { data } = await axios.post(
    reDraftbuildUrl(`regen_api/regeneration/add_bullet`),
    parammeters,
    userHeaders
  );
  return data;
};
const deleteBullets = async (parameter) => {
  const userHeaders = getUserDetails();
  const data = await axios.post(
    buildUrl(`2pager/view_v2/delete_sub_bullet`),
    parameter,
    userHeaders
  );
  return data;
};
export {
  getCimFileUpload,
  view2PagerLists,
  view2GenerationStatus,
  view2GenerationTrigger,
  generationCancel,
  fetchPopulateData,
  getCimPage,
  editAccept,
  editAcceptAll,
  getFeedback,
  getAllVersions,
  rePopulateData,
  deleteUplodedFiles,
  acceptRejectRedraft,
  createReDraft,
  createReDraftV2,
  deleteBullets,
};
