import React from "react";
import ReactApexChart from "react-apexcharts";

const FeedbackPieChart = ({ dataset }) => {
  const customColors = ["#4CAF50", "#F44336"];

  const options = {
    chart: {
      type: "pie",
    },
    tooltip: {
      custom: function ({ seriesIndex, series, dataPointIndex, w }) {
        const data = dataset[seriesIndex];
        return `
          <div style="padding: 10px; font-size: 14px; color: #fff; background: #333; border-radius: 4px;">
            <strong>Label:</strong> ${data.label} <br/>
            <strong>Value:</strong> ${data.value}% <br/>
            <strong>Count:</strong> ${data.count}
          </div>
        `;
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      formatter: function (seriesName, opts) {
        const index = opts.seriesIndex;
        return dataset[index].label;
      },
    },
    colors: customColors,
  };

  const series = dataset.map((item) => item.value);

  const chartData = {
    options: options,
    series: series,
  };

  return (
    <div>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="pie"
        height={350}
      />
    </div>
  );
};

export default FeedbackPieChart;
