import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  TablePagination,
  Tooltip,
} from "@mui/material";
import DifferenceIcon from "@mui/icons-material/Difference";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { fetchUsers, deleteUser } from "../service";

const UserManagement = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [userToDelete, setUserToDelete] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const loggedInUser = JSON.parse(localStorage.getItem("userLoginInfo"));

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getUsers = async () => {
    try {
      setIsLoading(true);
      const response = await fetchUsers(null);
      if (response?.status === "success") {
        setUsers(response?.data);
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const searchedUser = users?.filter((user) =>
    user?.user_name.toLowerCase().includes(searchQuery)
  );

  const paginatedUsers = searchedUser?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
    setPage(0);
  };

  const handleOpenDialog = (id) => {
    setUserToDelete(id);
    setOpenDialog(true);
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const response = await deleteUser(userToDelete, null);
      if (response?.status === "success") {
        window.location.reload();
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setIsLoading(false);
      setOpenDialog(false);
      setUserToDelete(null);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setUserToDelete(null);
  };
  useEffect(() => {
    getUsers();
  }, []);
  return (
    <>
      <Box>
        <Box className="addPortfolioArea">
          {/* <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/admin/user-management/0")}
            className="addPortfolio"
          >
            Add User
          </Button> */}
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            onChange={handleSearchChange}
            style={{ maxWidth: "250px" }}
          />
        </Box>
        <Paper>
          <TableContainer style={{ maxHeight: "400px", overflowY: "auto" }}>
            <Table>
              <TableHead
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#fff",
                  zIndex: 1,
                }}
              >
                <TableRow>
                  <TableCell style={{ textAlign: "left" }}>User Name</TableCell>
                  <TableCell style={{ textAlign: "left" }}>Email Id</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedUsers?.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ textAlign: "left" }}>
                      {user?.user_name}
                    </TableCell>
                    <TableCell style={{ textAlign: "left" }}>
                      {user?.email_id}
                    </TableCell>
                    <TableCell>{user?.user_role}</TableCell>
                    <TableCell>{user?.status}</TableCell>
                    <TableCell>
                      <Tooltip title="View/Edit" arrow>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            navigate(
                              `/admin/user-management/${user?.user_id}`,
                              {
                                state: user,
                              }
                            );
                          }}
                          disabled={user?.email_id === loggedInUser?.userEmail}
                        >
                          <DifferenceIcon />
                        </IconButton>
                      </Tooltip>
                      {/* <Tooltip title="Delete" arrow>
                        <IconButton
                          color="secondary"
                          onClick={() => handleOpenDialog(user?.user_id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={searchedUser?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
      </Box>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this user?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
      {isLoading && (
        <Box
          className="loaderPage"
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 2000,
          }}
        >
          <RotateRightIcon
            color="inherit"
            fontSize="large"
            sx={{ animation: "spin 2s infinite ease-in-out" }}
          />
        </Box>
      )}
    </>
  );
};

export default UserManagement;
