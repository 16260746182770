import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Grid,
  Typography,
  FormHelperText,
} from "@mui/material";
import UploadFiles from "./UploadFiles";
import InputAdornment from "@mui/material/InputAdornment";
import { fetchPortfolioData, submitPortfolio } from "./service";
import { toast } from "react-toastify";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import "./style.css";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useNavigate, useLocation } from "react-router-dom";

const AddPortfolioForm = ({ selectedDeal }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dealCloudId, setDealCloudId] = useState("");
  const [dealIdData, setDealIdData] = useState({
    companyName: "",
    fundType: "",
    dealName: "",
    docs: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [portfolioId, setPortfolioId] = useState("");
  const [oldPortfolioId, setOldPortfolioId] = useState([]);
  const [restoreFiles, setRestoreFiles] = useState([]);
  const [error, setError] = useState("");

  const handleDealCloudIdChange = (event) => {
    const isValid = /^[0-9]*$/.test(event.target.value);
    setError(
      isValid || event.target.value === ""
        ? ""
        : "Please enter a valid DealCloud ID number."
    );
    setDealCloudId(event.target.value);
  };

  const fetchDealIdData = async (id) => {
    try {
      setIsLoading(true);
      const response = await fetchPortfolioData(id);
      if (response?.status === "success") {
        setDealIdData({
          companyName: response?.data[0]?.company_name || "",
          fundType: response?.data[0]?.fund_type_specific || "",
          dealName: response?.data[0]?.deal_name || "",
          docs: response?.data[0]?.supplementary || [],
        });
      } else {
        toast.error("Invalid Deal Cloud ID!", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getDealIdData = () => {
    if (!dealCloudId) {
      toast.error("Please provide the Deal Cloud ID.", {
        position: "top-right",
        autoClose: 5000,
      });
      return;
    }
    fetchDealIdData(dealCloudId);
  };

  const renderInputField = (label, placeholder, value) => (
    <TextField
      label={label}
      placeholder={placeholder}
      variant="outlined"
      fullWidth
      margin="dense"
      className="compact-input"
      value={value || ""}
      size="small"
    />
  );

  const handlePortfolioId = (data) => {
    setPortfolioId(data);
  };
  const handleOldPortfolioId = (data) => {
    setOldPortfolioId(data);
  };
  const handleRestoreFiles = (data) => {
    setRestoreFiles(data);
  };
  const handleSubmit = () => {
    try {
      // setIsLoading(true);
      const response = submitPortfolio(restoreFiles, null);
      navigate("/admin/portco-kb-management");
      if (response) {
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
      });
    }
    // finally {
    //   setIsLoading(false);
    // }
  };

  useEffect(() => {
    if (location?.state !== null) {
      setDealCloudId(location?.state);
      fetchDealIdData(location?.state);
    }
  }, []);

  const isGetEnabled = location?.state === null;
console.log(location?.state);

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="8px"
      >
        <Typography variant="h6" gutterBottom>
          Manage Portfolio Companies Knowledge Base
        </Typography>
        <Typography
          variant="h7"
          gutterBottom
          onClick={() => navigate("/admin/portco-kb-management")}
          sx={{
            display: "inline-flex",
            alignItems: "center",
            cursor: "pointer",
            border: "1px solid",
            borderColor: "primary.main",
            borderRadius: "4px",
            padding: "4px 8px",
          }}
        >
          <ArrowBackIosNewOutlinedIcon
            sx={{ marginRight: "4px", fontSize: "12px" }}
          />
          Back
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Deal Cloud ID"
            placeholder="Enter Deal Cloud ID"
            variant="outlined"
            fullWidth
            margin="dense"
            value={dealCloudId}
            onChange={handleDealCloudIdChange}
            className="compact-input"
            disabled={!isGetEnabled}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={getDealIdData}
                    className="get-button"
                    size="small"
                    disabled={!isGetEnabled || error?.length > 0}
                  >
                    Get
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          {error && <FormHelperText error>{error}</FormHelperText>}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderInputField(
            "Deal Name",
            "Enter Deal Name",
            dealIdData?.dealName
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderInputField(
            "Fund Type Specific",
            "Enter Fund Type Specific",
            dealIdData?.fundType
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderInputField(
            "Company Name",
            "Enter Company Name",
            dealIdData?.companyName
          )}
        </Grid>
      </Grid>

      {dealIdData?.companyName?.length > 0 && (
        <UploadFiles
          dealData={dealIdData}
          getPortfolioId={handlePortfolioId}
          getOldPortfolioId={handleOldPortfolioId}
          getRestoreFiles={handleRestoreFiles}
          dealId={dealCloudId}
        />
      )}

      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={
            portfolioId === "" &&
            oldPortfolioId?.length === 0 &&
            restoreFiles?.length === 0
          }
        >
          Submit
        </Button>
      </Box>
      {isLoading && (
        <Box
          className="loaderPage"
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 2000,
          }}
        >
          <RotateRightIcon
            color="inherit"
            fontSize="large"
            sx={{ animation: "spin 2s infinite ease-in-out" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default AddPortfolioForm;
