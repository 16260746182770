import React, { useState, useEffect } from "react";
import {
  Box,
  Tabs,
  Tab,
  ThemeProvider,
  createTheme,
  Typography,
} from "@mui/material";
import { useNavigate, Outlet } from "react-router-dom";
import Header from "../drawer/Header";
import "./style.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#292F62",
    },
    secondary: {
      main: "#f50057",
    },
  },
});

const AdminPanel = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [releaseVersion, setReleaseVersion] = useState("NA");
  const navigate = useNavigate();
  const pathURL = window.location.pathname;

  const handleTabChange = (newValue) => {
    setTabIndex(newValue);
    if (newValue === 0) {
      navigate("/admin/portco-kb-management", { replace: true });
    } else if (newValue === 1) {
      navigate("/admin/user-management", { replace: true });
    } else if (newValue === 2) {
      navigate("/admin/failed-generations", { replace: true });
    } else if (newValue === 3) {
      navigate("/admin/feedback", { replace: true });
    }
  };

  useEffect(() => {
    setReleaseVersion(JSON.parse(localStorage.getItem("releaseVersion")));
  }, [releaseVersion]);

  useEffect(() => {
    if (pathURL === "/admin/portco-kb-management") {
      setTabIndex(0);
    } else if (pathURL === "/admin/user-management") {
      setTabIndex(1);
    } else if (pathURL === "/admin/failed-generations") {
      setTabIndex(2);
    } else if (pathURL === "/admin/feedback") {
      setTabIndex(3);
    }
  }, []);
  
  return (
    <main>
      <Header />
      <ThemeProvider theme={theme}>
        <Box className="adminPanel">
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tabIndex}
            // onChange={handleTabChange}
            className="tabArea"
          >
            <Tab
              label="PortCo KB Management"
              onClick={() => handleTabChange(0)}
            />
            <Tab label="User Management" onClick={() => handleTabChange(1)} />
            <Tab
              label="Failed Generations"
              onClick={() => handleTabChange(2)}
            />
            <Tab label="Feedback Report" onClick={() => handleTabChange(3)} />
          </Tabs>

          <Box className="contentArea">
            <Outlet />
          </Box>
        </Box>

        <Typography style={{ padding: 30, fontSize: 14, textAlign: "center" }}>
          &#169; All Rights Reserved (2025) Veritas Capital : Release Version
          :&nbsp;
          <Typography
            component="span"
            style={{ cursor: "pointer", color: "#33348e", fontSize: 14 }}
            onClick={() => {
              window.open(`${window.location.origin}/release_notes`, "_blank");
            }}
          >
            {releaseVersion}
          </Typography>
        </Typography>
      </ThemeProvider>
    </main>
  );
};

export default AdminPanel;
