import axios from "axios";
import { environment } from "../../environment";

const buildUrl = (path) => {
  return environment.baseUrl + path;
};

const getUserDetails = () => {
  const response = JSON.parse(localStorage.getItem("userLoginInfo"));
  const data = {
    email_id: response.userEmail,
    access_token: response.access_token,
  };

  const options = {
    headers: data,
  };
  return options;
};

const fetchDealData = async (deal_name) => {
  const { data } = await axios.post(
    buildUrl(
      `cim_api/cim/populate_data?deal_cloud_id=${deal_name}&output_type=json`
    ),
    null,
    getUserDetails()
  );
  return data;
};

const fetchPortfolioData = async (dealId) => {
  const { data } = await axios.get(
    buildUrl(
      `portco_api/portfolio/view_portfolio_data?deal_cloud_id=${dealId}`
    ),
    null,
    getUserDetails()
  );
  return data;
};

const deletePortfolio = async (dealId, portfolioId) => {
  const { data } = await axios.post(
    buildUrl(
      `portco_api/portfolio/delete_portfolio_data?deal_cloud_id=${dealId}&portfolio_id=${portfolioId}&output_type=json`
    ),
    null,
    getUserDetails()
  );
  return data;
};

const downloadPortfolio = async (portfolioId) => {
  const data = await axios.get(
    buildUrl(
      `portco_api/portfolio/export_portfolio?portfolio_id=${portfolioId}`
    ),
    {
      responseType: "blob", // Important for handling binary data
    },
    null,
    getUserDetails()
  );
  console.log(data);
  return data;
};

const submitPortfolio = async (portfolioId, dealId) => {
  const { data } = await axios.post(
    buildUrl(
      `portco_api/portfolio/process_portfolio?portfolio_id=${portfolioId}&deal_cloud_id=${dealId}&output_type=json`
    ),
    null,
    getUserDetails()
  );
  return data;
};

const fetchUsers = async (userId) => {
  const { data } = await axios.get(
    buildUrl(`users_api/users/view_user_data?user_id=${userId}`),
    null,
    getUserDetails()
  );
  return data;
};

const deleteUser = async (userId) => {
  const { data } = await axios.post(
    buildUrl(`users_api/users/delete_user?user_id=${userId}&output_type=json`),
    null,
    getUserDetails()
  );
  return data;
};

const submitUser = async (userId, userName, role, status) => {
  const { data } = await axios.post(
    buildUrl(
      `users_api/users/edit_user_detail?user_id=${userId}&user_name=${userName}&role=${role}&status=${
        status === "Active" ? true : false
      }&output_type=json`
    ),
    null,
    getUserDetails()
  );
  return data;
};

const fetchFailedGenerations = async (docType) => {
  const { data } = await axios.post(
    buildUrl(
      `log_management_api/failure_log_management/populate_failed_generations?doc_type=${docType}`
    ),
    null,
    getUserDetails()
  );
  return data;
};

const downloadGeneration = async (blobList) => {
  const data = await axios.post(
    buildUrl(`log_management_api/failure_log_management/download_logs_zip`),

    {
      available_blob_list: blobList,
    },
    {
      headers: getUserDetails(),
      responseType: "blob",
    }
  );
  return data;
};

const fetchFeedbackData = async (user, start, end) => {
  console.log(user, start, end);
  const { data } = await axios.post(
    buildUrl(
      `feedback_management_api/feedback_management/get_feedback_data?user_name=${user}&start_date=${start}&end_date=${end}`
    ),
    null,
    getUserDetails()
  );
  return data;
};

const fetchUserList = async (userId) => {
  const { data } = await axios.post(
    buildUrl(`feedback_management_api/feedback_management/get_user_list`),
    null,
    getUserDetails()
  );
  return data;
};

const downloadFeedback = async (user, start, end) => {
  const data = await axios.get(
    buildUrl(
      `feedback_management_api/feedback_management/export_session_data?user_name=${user}&start_date=${start}&end_date=${end}`
    ),

    {
      responseType: "blob", // Important for handling binary data
    },
    null,
    getUserDetails()
  );
  return data;
};

export {
  fetchDealData,
  fetchPortfolioData,
  deletePortfolio,
  downloadPortfolio,
  submitPortfolio,
  fetchUsers,
  deleteUser,
  submitUser,
  fetchFailedGenerations,
  downloadGeneration,
  fetchFeedbackData,
  fetchUserList,
  downloadFeedback,
};
