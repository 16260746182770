import React, { useRef, useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Switch,
  FormControlLabel,
  Tooltip,
  Card,
  Button,
  Box,
  FormHelperText,
  InputAdornment,
  TextField,
} from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Header from "../drawer/Header";
import "./DocumentEditPageStyle.css";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { styled } from "@mui/material/styles";
import { Modal, Paper } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import refreshImage from "../../Assets/Icons/Frame 290.svg";
import sideImage from "../../Assets/Icons/Vector 62.svg";
import InputBase from "@mui/material/InputBase";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Stack from "@mui/material/Stack";
import Check from "@mui/icons-material/Check";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import {
  view2GenerationTrigger,
  view2GenerationStatus,
  generationCancel,
  fetchPopulateData,
  rePopulateData,
} from "./service";
import { environment } from "../../environment";
import { useLocation } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { updateBTEV } from "../redux/reducer/twoPagerSlice";
import { useDispatch } from "react-redux";
import FileUploadStatus from "./FileUploadStatus";
import ExpertFileUpload from "./ExpertFileUpload";
import InfoIcon from "@mui/icons-material/Info";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadPortfolio } from "../AdminPanel/service";
import CustomBreadcrumb from "../drawer/CustomBreadcrumb";

export default function DocumentEditPage({ onExternalToggle }) {
  const urlParams = useParams();
  const params = urlParams.userId;
  const location = useLocation();
  const [releaseVersion, setReleaseVersion] = useState("NA");
  const [open, setOpen] = useState(false);
  const [openCanclePopUp, setOpenCanclePopUp] = useState(false);
  const [cardShow, setCardOpen] = useState(false);
  const [cancle, setCancle] = useState("yellow");
  const [dealCloudId, setDealCloudId] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [genrateTwopagerStatus, setGenrateTwopagerStatus] = useState(false);
  const [genrationTrigger, setGenrationTrigger] = useState();
  const [genrationStatus, setGenrationStatus] = useState();
  const [dealName, setDealName] = useState("");
  const [fundType, setFundType] = useState("");
  const [statusApiIntervalId, setStatusApiIntervalId] = useState(null);
  const [hasRun, sethasRun] = useState(false);
  const [dealCloudIdInprogress, setDealCloudIdInprogress] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [expertUploadedFiles, setExpertUploadedFiles] = useState([]);
  const [reportId, setReportId] = useState("");
  const [expertReportId, setExpertReportId] = useState("");
  const [oldReportId, setOldReportId] = useState([]);
  const [expertOldReportId, setExpertOldReportId] = useState([]);
  const [isExternalData, setIsExternalData] = useState(true);
  const [isAPILoading, setIsAPILoading] = useState(false);
  const isAdmin = localStorage.getItem("admin");

  const handleExternalData = (event) => {
    const newState = event.target.checked;
    setIsExternalData(newState);
    if (onExternalToggle) onExternalToggle(newState);
  };

  const navigate = useNavigate();
  const buildUrl = (path) => {
    return environment.baseUrl + path;
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseCanclePopUP = () => {
    setOpenCanclePopUp(false);
  };
  const handleOpenCanclePopUP = () => {
    setOpenCanclePopUp(true);
  };

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#eaeaf0",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#eaeaf0",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 12,
    alignItems: "center",
    borderRadius: "50%", // Add rounded border
    border: `2px solid ${theme.palette.mode === "dark" ? theme.palette.grey[700] : "white"
      }`, // Border color same as icon color
    boxSizing: "content-box", // Ensure the border is outside the size of the icon
    padding: 3, // Adjust padding as needed
    ...(ownerState && {
      color: cancle,
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "white",
      zIndex: 1,
      fontSize: 25,
      borderRadius: "60%",
      backgroundColor: "green",
    },
    "& .QontoStepIcon-circle": {
      width: 25,
      height: 25,
      borderRadius: "80%",
      zIndex: 1,
      backgroundColor: "currentColor",
    },
  }));

  const [statusData, setStatusData] = useState({
    cim_status: "",
    pager_status: "",
    Uploaded: [],
    Completed: [],
    InProgress: [],
    Cancelled: [],
    Failed: [],
    Queued: [],
    supporting_document_status: "",
    expert_call_note_status: "",
    insight_status: "",
    external_data_status: "",
  });

  const steps = [
    "CIM Document Processing",
    "External Data Processing",
    "Research Report(s)",
    "Expert Call Note(s)",
    "Key Information",
    "Business Description",
    "Merits and Considerations ",
    "Preliminary Investment Thesis ",
    "Financial Overview ",
    "Ownership /Management ",
    "Situation Overview / Next Steps ",
    "Past Similar Looks ",
    "Public Comps ",
    "Other Relevant Information ",
    "Insights Generation",
    "Two Pager Generation Status",
  ];
  const [selectedFile, setSelectedFile] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [docName, setDocName] = useState("");
  const [newCimIdGenrated, setNewCimIdGenrated] = useState("");
  const [cancelButton, setCancelButton] = useState(false);
  const bottomRef = useRef(null);
  const [uploadButtonStatus, setUploadButtonStatus] = useState(true);
  const [genrationButtonActive, setGenrationButtonActive] = useState(true);
  const [error, setError] = useState("");
  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    dispatch(updateBTEV(false));
    setReleaseVersion(JSON.parse(localStorage.getItem("releaseVersion")));
  }, [releaseVersion]);

  useEffect(() => {
    if (cardShow || params) {
      scrollToBottom();
    }
  }, [cardShow, params]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const [activeStep, setActiveStep] = React.useState(0);

  useEffect(() => {
    return () => {
      clearInterval(statusApiIntervalId);
    };
    setGenrationButtonActive(true);
  }, [statusApiIntervalId]);

  useEffect(() => {
    if ((genrationStatus?.["2pager_status"] === "Completed"
      && genrationStatus?.["insight_status"] === "Failed") ||
      (genrationStatus?.["2pager_status"] === "Completed"
        && genrationStatus?.["insight_status"] === "Completed")
    ) {
      clearInterval(statusApiIntervalId);
    }
  }, [genrationStatus]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const clearUploadFile = () => {
    document.getElementById("file-upload").value = "";
    setSelectedFile(null);
    setCardOpen(false);
    setGenrateTwopagerStatus(false);
  };

  const handleDashboard = () => {
    navigate("/");
  };
  const getUserDetails = () => {
    const response = JSON.parse(localStorage.getItem("userLoginInfo"));
    const data = {
      email_id: response?.userEmail,
      access_token: response?.access_token,
    };

    const options = {
      headers: data,
    };
    return options.headers;
  };

  const handleUpload = async () => {
    try {
      setIsAPILoading(true);
      if (!selectedFile) {
        toast.error("Please select a file to upload.", {
          position: "top-right",
          autoClose: 5000,
        });
        return;
      }

      const allowedTypes = ["application/pdf"];
      if (!allowedTypes.includes(selectedFile.type)) {
        toast.warn("Invalid file type. Please upload a PDF file.", {
          position: "top-right",
          autoClose: 5000,
        });
        return;
      }

      setLoading(true);
      setOpen(false);
      setUploadButtonStatus(false);
      const errorMessage1 = "File uploading, please wait...";
      const toastId = toast.success(errorMessage1, {
        position: "top-right",
        autoClose: false,
      });

      const formData = new FormData();
      formData.append("uploaded_file", selectedFile);

      const userHeaders = getUserDetails();

      const response = await axios.post(
        buildUrl(
          `cim_api/cim/upload?company_name=${encodeURIComponent(
            companyName
          )}&deal_cloud_id=${dealCloudId}&document_name=${encodeURIComponent(
            documentName
          )}`
        ),
        formData,
        {
          headers: {
            ...userHeaders,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response?.status === 200) {
        toast.dismiss(toastId);
        setNewCimIdGenrated(response?.data.data.cim_id);
        setGenrateTwopagerStatus(true);
        setUploadButtonStatus(true);
        toast.success("File uploaded successfully!", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      setOpen(false);
      console.error("Error uploading file:", error.response);

      const errorMessage =
        error.response?.data?.detail ||
        "Error uploading file. Please try again.";

      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setLoading(false);
      setIsAPILoading(false);
    }
  };

  const fetchUploadResearch = async (pagerID) => {
    try {
      setIsAPILoading(true);
      const response = await rePopulateData(pagerID);
      if (response) {
        setGenrateTwopagerStatus(true);
        setNewCimIdGenrated(
          response?.data?.cim_id === null ? "" : response?.data?.cim_id
        );
        setDealCloudId(
          response?.data?.deal_cloud_id === null
            ? ""
            : response?.data?.deal_cloud_id
        );
        setDealName(
          response?.data?.deal_name === null ? "" : response?.data?.deal_name
        );
        setFundType(
          response?.data?.fund_type_specific === null
            ? ""
            : response?.data?.fund_type_specific
        );
        setCompanyName(
          response?.data?.company_name === null
            ? ""
            : response?.data?.company_name
        );
        setDocName(
          response?.data?.cim_document_name === null
            ? ""
            : response?.data?.cim_document_name
        );
        setDocumentName(
          response?.data?.document_name_2pager === null
            ? ""
            : response?.data?.document_name_2pager
        );
        setUploadedFiles(
          response?.data?.report_data?.length === 0
            ? []
            : response?.data?.report_data
        );
        setExpertUploadedFiles(
          response?.data?.expert_call_note_data?.length === 0
            ? []
            : response?.data?.expert_call_note_data
        );
        setOldReportId(
          response?.data?.report_data?.length === 0
            ? []
            : response?.data?.report_data
        );
        setExpertOldReportId(
          response?.data?.expert_call_note_data?.length === 0
            ? []
            : response?.data?.expert_call_note_data
        );
        setIsExternalData(
          response?.data?.external_source === "True" ? true : false
        );
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 5000, // Close the notification after 5 seconds
        });
      }
    } catch (response) {
      toast.error(response?.status, {
        position: "top-right",
        autoClose: 5000, // Close the notification after 5 seconds
      });
    } finally {
      setIsAPILoading(false);
    }
  };

  useEffect(() => {
    if (params) {
      if (location?.state === "UploadResearch") {
        setCardOpen(false);
      } else if (
        location?.state?.status === "Failed" ||
        location?.state?.status === "Cancelled"
      ) {
        setCardOpen(true);
        fetchGenrationStatusInprogress();
      } else {
        setCardOpen(true);
        fetchGenrationStatusInprogress(); // Initial call
        const interval = setInterval(fetchGenrationStatusInprogress, 5000); // Repeat every 5 seconds
        setStatusApiIntervalId(interval);
        return () => clearInterval(interval); // Cleanup on unmount
      }
    }
  }, [params]);

  useEffect(() => {
    if (dealCloudIdInprogress && !hasRun) {
      fetchPopulateDealdata(dealCloudIdInprogress);
      sethasRun(true);
    }
  }, [dealCloudIdInprogress, hasRun]);
  useEffect(() => {
    if (location?.state === "UploadResearch") {
      setCardOpen(false);
      fetchUploadResearch(params);
    }
  }, []);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (params) {
      fetchGenrationStatus();
    }
  };

  const fetchGenrationStatus = async () => {
    try {
      const response = await view2GenerationStatus(
        params,
        reportId,
        expertReportId
      );
      if (response !== undefined) {
        setGenrationStatus(response?.data);
      }

      setDealCloudId(response?.data.deal_id);
      setDocName(response?.data.cim_document_name);
      setCompanyName(response?.data.company_name);
    } catch (error) {
      console.error("Error fetching generation status:", error);
    }
  };
  const fetchGenrationStatusInprogress = async () => {
    try {
      const response = await view2GenerationStatus(
        params,
        reportId,
        expertReportId
      );
      if (response !== undefined) {
        setGenrationStatus(response?.data);
      }

      setDealCloudId(response?.data.deal_id);
      setDealCloudIdInprogress(response?.data.deal_id);
      setDocName(location?.state?.doc);
      setDocumentName(response?.data.cim_document_name);
      setCompanyName(response?.data.company_name);
    } catch (error) {
      console.error("Error fetching generation status:", error);
    }
  };

  const messagePopUp = () => {
    const Message = "Generation is already in progress ";
    toast.success(Message, {
      position: "top-right",
      autoClose: 5000, // Close the notification after 5 seconds
    });
  };

  const fetchGenrationTrigger = async () => {
    const errorMessage1 = "Generation will begin shortly";
    setIsAPILoading(true);
    const toastId1 = toast.success(errorMessage1, {
      position: "top-right",
      autoClose: false,
    });
    const parameter = {
      root: location?.state === "UploadResearch" ? "True" : "False",
      old_report_id: oldReportId,
      two_pager_id: params,
      old_expert_call_note_id: expertOldReportId,
    };
    try {
      const response = await view2GenerationTrigger(
        newCimIdGenrated,
        companyName,
        documentName,
        dealCloudId,
        reportId,
        expertReportId,
        parameter,
        isExternalData
      );
      setGenrationTrigger(response?.data?.two_pager_id);
      setReportId(response?.data?.report_id);
      setExpertReportId(response?.data?.expert_call_note_id);
      if (response?.data?.two_pager_id) {
        setCancelButton(true);
        const intervalId = setInterval(async () => {
          try {
            const res = await view2GenerationStatus(
              response?.data.two_pager_id,
              response?.data?.report_id,
              response?.data?.expert_call_note_id
            );
            setIsAPILoading(true);
            setGenrationButtonActive(false);
            if (res !== undefined) {
              setGenrationStatus(res?.data);
              if (res.data.cim_status !== "") {
                toast.dismiss(toastId1);
                setIsAPILoading(false);
              }
            }
          } catch (statusError) {
            console.error("Error fetching generation status:", statusError);
          }
        }, 5000);
        setStatusApiIntervalId(intervalId);
      }
    } catch (error) {
      setIsAPILoading(false);
      toast.dismiss(toastId1);
      const toastFailed = toast.error("Generation failed!", {
        position: "top-right",
        autoClose: false,
      });
      toastFailed();
      console.log("hi");


      console.error("Error triggering generation:", error);
    }
    // finally {
    //   setIsAPILoading(false);
    // }
  };

  const handleDocumentNameChange = (event) => {
    setDocumentName(event.target.value);
  };

  const genrateCard = () => {
    setCardOpen(true);
    if (!params || location?.state === "UploadResearch") {
      fetchGenrationTrigger();
    } else {
      toast.success("Generation is already in progress", {
        position: "top-right",
        autoClose: 5000, // Close the notification after 5 seconds
      });
    }
  };

  useEffect(() => {
    if (genrationStatus?.sections?.length > 0) {
      const newUploaded = genrationStatus?.sections[0]?.Uploaded?.map(
        (a) => a + 1
      );
      const newCompleted = genrationStatus?.sections[1]?.Completed?.map(
        (a) => a + 1
      );
      const newInProgress = genrationStatus?.sections[2]["In Progress"]?.map(
        (a) => a + 1
      );
      const newCancelled = genrationStatus?.sections[3]?.Cancelled?.map(
        (a) => a + 1
      );
      const newFailed = genrationStatus?.sections[4]?.Failed?.map((a) => a + 1);
      const newQueued = genrationStatus?.sections[5]?.Queued?.map((a) => a + 1);

      setStatusData((prevState) => ({
        ...prevState,
        cim_status: genrationStatus?.cim_status,
        pager_status: genrationStatus["2pager_status"],
        Uploaded: newUploaded,
        Completed: newCompleted,
        InProgress: newInProgress,
        Cancelled: newCancelled,
        Failed: newFailed,
        Queued: newQueued,
        supporting_document_status: genrationStatus?.supporting_document_status,
        expert_call_note_status: genrationStatus?.expert_call_note_status,
        insight_status: genrationStatus?.insight_status,
        external_data_status: genrationStatus?.external_data_status,
      }));
    }
  }, [genrationStatus]);

  function QontoStepIcon(props) {
    const { active, completed, className, icon } = props;

    return (
      <QontoStepIconRoot ownerState={active} className={className}>
        {(statusData?.cim_status === "Completed" && icon === 1) ||
          (statusData?.external_data_status === "Completed" && icon === 2) ||
          (statusData["pager_status"] === "Completed" && icon === 16) ||
          (statusData?.supporting_document_status === "Completed" &&
            icon === 3) ||
          (statusData?.expert_call_note_status === "Completed" && icon === 4) ||
          (statusData?.insight_status === "Completed" && icon === 15) ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (statusData?.cim_status === "Uploaded" && icon === 1) ||
          (statusData?.external_data_status === "Uploaded" && icon === 2) ||
          (statusData["pager_status"] === "Uploaded" && icon === 16) ||
          (statusData?.supporting_document_status === "Uploaded" &&
            icon === 3) ||
          (statusData?.expert_call_note_status === "Uploaded" && icon === 4) ||
          (statusData?.insight_status === "Uploaded" && icon === 15) ? (
          <div
            className="QontoStepIcon-circle"
            style={{ backgroundColor: "aqua" }}
          />
        ) : (statusData?.cim_status === "In Progress" && icon === 1) ||
          (statusData?.external_data_status === "In Progress" && icon === 2) ||
          (statusData["pager_status"] === "In Progress" && icon === 16) ||
          (statusData?.supporting_document_status === "In Progress" &&
            icon === 3) ||
          (statusData?.expert_call_note_status === "In Progress" &&
            icon === 4) ||
          (statusData?.insight_status === "In Progress" && icon === 15) ? (
          <div
            className="QontoStepIcon-circle"
            style={{ backgroundColor: "yellow" }}
          />
        ) : (statusData?.cim_status === "Cancelled" && icon === 1) ||
          (statusData?.external_data_status === "Cancelled" && icon === 2) ||
          (statusData["pager_status"] === "Cancelled" && icon === 16) ||
          (statusData?.supporting_document_status === "Cancelled" &&
            icon === 3) ||
          (statusData?.expert_call_note_status === "Cancelled" && icon === 4) ||
          (statusData?.insight_status === "Cancelled" && icon === 15) ? (
          <div
            className="QontoStepIcon-circle"
            style={{ backgroundColor: "orange" }}
          />
        ) : (statusData?.cim_status === "Failed" && icon === 1) ||
          (statusData?.external_data_status === "Failed" && icon === 2) ||
          (statusData["pager_status"] === "Failed" && icon === 16) ||
          (statusData?.supporting_document_status === "Failed" && icon === 3) ||
          (statusData?.expert_call_note_status === "Failed" && icon === 4) ||
          (statusData?.insight_status === "Failed" && icon === 15) ? (
          <div
            className="QontoStepIcon-circle"
            style={{ backgroundColor: "red" }}
          />
        ) : (statusData?.cim_status === "Queued" && icon === 1) ||
          (statusData?.external_data_status === "Queued" && icon === 2) ||
          (statusData["pager_status"] === "Queued" && icon === 16) ||
          (statusData?.supporting_document_status === "Queued" && icon === 3) ||
          (statusData?.expert_call_note_status === "Queued" && icon === 4) ||
          (statusData?.insight_status === "Queued" && icon === 15) ? (
          <div className="QontoStepIcon-circle" />
        ) : statusData?.Uploaded?.includes(icon) ? (
          <div
            className="QontoStepIcon-circle"
            style={{ backgroundColor: "aqua" }}
          />
        ) : statusData?.Completed?.includes(icon) ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : statusData?.InProgress?.includes(icon) ? (
          <div
            className="QontoStepIcon-circle"
            style={{ backgroundColor: "yellow" }}
          />
        ) : statusData?.Cancelled?.includes(icon) ? (
          <div
            className="QontoStepIcon-circle"
            style={{ backgroundColor: "orange" }}
          />
        ) : statusData?.Failed?.includes(icon) ? (
          <div
            className="QontoStepIcon-circle"
            style={{ backgroundColor: "red" }}
          />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  const genrationCancelTrigger = async () => {
    const cancelMSG = "Cancellation in progress!";
    const toastCancel = toast.success(cancelMSG, {
      position: "top-right",
      autoClose: false,
    });

    try {
      setOpenCanclePopUp(false);
      const response =
        location?.state?.status === "In Progress"
          ? await generationCancel(params)
          : await generationCancel(genrationTrigger);
      if (response) {
        toast.dismiss(toastCancel);
        clearInterval(statusApiIntervalId);
        let intervalIdStatus = setInterval(function () {
          if (params) {
            const res = view2GenerationStatus(params, reportId, expertReportId);
            if (res !== undefined) {
              setGenrationStatus(res?.data);
            }
          } else {
            const res = view2GenerationStatus(
              genrationTrigger,
              reportId,
              expertReportId
            );
            if (res !== undefined) {
              setGenrationStatus(res?.data);
            }
          }
        }, 5000);
        setCancle("red");
        setTimeout(function () {
          clearInterval(intervalIdStatus);
        }, 10000);

        const errorMessage = "Generation of two pager cancelled";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000, // Close the notification after 3 seconds
        });
      }
    } catch (error) {
      console.error("Error triggering generation:", error);
      setOpenCanclePopUp(false);
      toast.dismiss(toastCancel);
      const cancelFailedMSG = "Cancellation failed!";
      const toastCancelFailed = toast.error(cancelFailedMSG, {
        position: "top-right",
        autoClose: true,
      });
    }
  };

  const handleDealCloudIdChange = (event) => {
    const isValid = /^[0-9]*$/.test(event.target.value);
    setError(
      isValid || event.target.value === ""
        ? ""
        : "Please enter a valid DealCloud ID number."
    );
    setDealCloudId(event.target.value);
    if (event.target.value.length) {
      setDealName("");
      setCompanyName("");
      setFundType("");
      setDocumentName("");
      setSelectedFile(null);
      setGenrateTwopagerStatus(false);
      document.getElementById("file-upload").value = "";
    }
  };
  const getDealIddata = () => {
    fetchPopulateDealdata(dealCloudId);
  };

  const fetchPopulateDealdata = async (id) => {
    try {
      const response = await fetchPopulateData(id);

      if (response) {
        setCompanyName(
          response?.company_name === null ? "" : response?.company_name
        );
        setDealName(response?.deal_name === null ? "" : response?.deal_name);
        setFundType(
          response?.fund_type_specific === null
            ? ""
            : response?.fund_type_specific
        );
      }
      if (!params) {
        toast.success(response?.message, {
          position: "top-right",
          autoClose: 5000, // Close the notification after 5 seconds
        });
      }
    } catch (response) {
      if (id) {
        toast.error(response?.response?.statusText, {
          position: "top-right",
          autoClose: 5000, // Close the notification after 5 seconds
        });
      } else {
        toast.error("Please provide the Deal Cloud ID.", {
          position: "top-right",
          autoClose: 5000, // Close the notification after 5 seconds
        });
      }
    }
  };

  const handleViewGenrateFile = () => {
    if (genrationTrigger) {
      navigate(`/view-generate-2pager/${genrationTrigger}`, {
        state: { doc: companyName },
      });
    } else {
      navigate(`/view-generate-2pager/${params}`, {
        state: { doc: companyName },
      });
    }
  };

  const onDrop = (acceptedFiles) => {
    const pdfFiles = acceptedFiles.filter(
      (file) => file.type === "application/pdf"
    );
    if (pdfFiles.length === 0) {
      alert("Only PDF files are allowed!");
    } else {
      setSelectedFile(pdfFiles[0]);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/pdf",
    onDrop,
    multiple: false,
  });

  const handleReportId = (data) => {
    setReportId(data);
  };
  const handleExpertReportId = (data) => {
    setExpertReportId(data);
  };

  const handleOldReportId = (data) => {
    setOldReportId(data);
  };

  const handleExpertOldReportId = (data) => {
    setExpertOldReportId(data);
  };

  const handleDownload = async (filename, portfolioId) => {
    try {
      setIsAPILoading(true);
      const newFileName = filename.replace(/\.[^/.]+$/, `.${"pdf"}`);
      const response = await downloadPortfolio(portfolioId);
      if (response) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${newFileName}`);
        document.body.appendChild(link);
        link.click();
        link.remove();

        toast.success("File downloaded successfully!", {
          position: "top-right",
          autoClose: 5000, // Close the notification after 5 seconds
        });
      }
    } catch (response) {
      toast.error(response?.status, {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setIsAPILoading(false);
    }
  };
  const renderInputField = (label, placeholder, value) => (
    <TextField
      label={label}
      placeholder={placeholder}
      variant="outlined"
      fullWidth
      margin="dense"
      className="compact-input"
      value={value || ""}
      size="small"
      style={{ cursor: "not-allowed" }}
      disabled={!dealCloudId}
    />
  );
  const isCancelDisabled =
    statusData["pager_status"] === "Completed" ||
    statusData["pager_status"] === "Cancelled" ||
    statusData["pager_status"] === "Failed";

  return (
    <>
      <Header />
      <CustomBreadcrumb showBack={true} title={"Generate New Two-Pager"} />
      <Box className="formArea">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "inherit",
                color: "#292f62",
                borderBottom: "2px solid #e0e0e0",
                paddingBottom: "8px",
                marginBottom: "16px",
              }}
            >
              {"Deal Details"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Deal Cloud ID"
              placeholder="Enter Deal Cloud ID"
              variant="outlined"
              fullWidth
              margin="dense"
              value={dealCloudId}
              onChange={handleDealCloudIdChange}
              className="compact-input get-input"
              disabled={params !== undefined}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => getDealIddata()}
                      className="get-button"
                      size="small"
                      disabled={genrateTwopagerStatus || error?.length > 0}
                    >
                      Get
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
            {error && <FormHelperText error>{error}</FormHelperText>}
          </Grid>
          <Grid item xs={12} sm={4}>
            {renderInputField("Deal Name", "Enter Deal Name", dealName)}
          </Grid>
          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} sm={4}>
            {renderInputField(
              "Fund Type Specific",
              "Enter Fund Type Specific",
              fundType
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            {renderInputField(
              "Company Name",
              "Enter Company Name",
              companyName
            )}
          </Grid>
          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label={"Document Name"}
              placeholder={"Enter Document Name"}
              variant="outlined"
              fullWidth
              margin="dense"
              className="compact-input"
              value={documentName}
              size="small"
              onChange={handleDocumentNameChange}
              disabled={!dealCloudId}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            style={{ display: "flex", alignItems: "center" }}
          >
            <TextField
              label="Document Name"
              placeholder="Enter Document Name"
              variant="outlined"
              fullWidth
              margin="dense"
              value={params ? docName : selectedFile ? selectedFile.name : ""}
              onChange={handleFileChange}
              className="compact-input get-input"
              disabled={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {/* <Button
                      variant="contained"
                      color="primary"
                      onClick={handleOpen}
                      className="get-button"
                      size="small"
                      disabled={!documentName && params && !uploadButtonStatus}
                    >
                      upload
                    </Button> */}

                    {documentName && !params && uploadButtonStatus ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpen}
                        className="get-button"
                        size="small"
                      >
                        upload
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpen}
                        className="get-button"
                        size="small"
                        disabled={true}
                      >
                        upload
                      </Button>
                    )}
                  </InputAdornment>
                ),
              }}
            />

            {selectedFile && (
              <IconButton aria-label="delete" color="primary">
                <DeleteOutlineIcon
                  style={{ color: "#FF4E28" }}
                  onClick={() => clearUploadFile()}
                />
              </IconButton>
            )}
            {isAdmin === "yes" && location?.state === "UploadResearch" && (
              <Tooltip title="Download" arrow>
                <IconButton
                  style={{
                    color: "#61a3ff",
                    padding: 0,
                  }}
                  className="downloadFileButton"
                  onClick={() => handleDownload(docName, newCimIdGenrated)}
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
          {genrateTwopagerStatus && (
            <Grid item xs={12} sm={12}>
              <Box>
                <Box display="flex" alignItems="center" gap={1}>
                  <InputLabel
                    htmlFor="custom-toggle"
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    External Data Processing
                  </InputLabel>
                  <Tooltip
                    title={
                      <>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            lineHeight: "1.2",
                          }}
                        >
                          When turned on, retrieves external data from sources
                          such as Congress.gov.
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            lineHeight: "1.2",
                          }}
                        >
                          When turned off, generation is faster, using only the
                          CIM and uploaded supporting documents (if any).
                        </Typography>
                      </>
                    }
                  >
                    <InfoIcon
                      sx={{
                        fontSize: "16px",
                        color: "gray",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Box>
                <FormControlLabel
                  control={
                    <Switch
                      id="custom-toggle"
                      checked={isExternalData}
                      onChange={handleExternalData}
                      size="medium"
                      sx={{
                        "& .MuiSwitch-switchBase.Mui-checked": {
                          color: "rgb(41, 47, 98)",
                        },
                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                        {
                          backgroundColor: "rgb(41, 47, 98)",
                        },
                        "& .MuiSwitch-track": {
                          backgroundColor: "rgb(41, 47, 98)",
                        },
                        cursor: !genrateTwopagerStatus
                          ? "not-allowed"
                          : "pointer",
                      }}
                      disabled={!genrateTwopagerStatus}
                    />
                  }
                  label=""
                />
              </Box>
            </Grid>
          )}
          {genrateTwopagerStatus && (
            <Grid item xs={12} sm={8}>
              <InputLabel
                htmlFor="Document-company"
                sx={{ fontSize: "12px", mt: 1.5 }}
              >
                Supporting Document(s)
              </InputLabel>
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  mt: 1,
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <FileUploadStatus
                    key="file-upload-status"
                    uploadedFiles={uploadedFiles}
                    clickable={genrateTwopagerStatus}
                    companyName={encodeURIComponent(companyName)}
                    dealCloudId={dealCloudId}
                    getReportId={handleReportId}
                    documentName={encodeURIComponent(documentName)}
                    getOldReportId={handleOldReportId}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <ExpertFileUpload
                    key="expert-upload-status"
                    expertUploadedFiles={expertUploadedFiles}
                    clickable={genrateTwopagerStatus}
                    companyName={encodeURIComponent(companyName)}
                    dealCloudId={dealCloudId}
                    getExpertReportId={handleExpertReportId}
                    documentName={encodeURIComponent(documentName)}
                    getExpertOldReportId={handleExpertOldReportId}
                  />
                </Box>
              </Box>
            </Grid>
          )}
          <Grid item xs={12} sm={8}>
            {statusData["pager_status"] === "Cancelled" ? (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#C5C5D6",
                  margin: 0,
                  borderRadius: 0,
                  textTransform: "capitalize",
                }}
                disabled
              >
                Generate Two-Pager
              </Button>
            ) : genrateTwopagerStatus || params ? (
              genrationButtonActive ? (
                <>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#292F62",
                      margin: 0,
                      borderRadius: 0,
                      textTransform: "capitalize",
                    }}
                    onClick={genrateCard}
                  >
                    Generate Two-Pager
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#292F62",
                      margin: 0,
                      borderRadius: 0,
                      textTransform: "capitalize",
                    }}
                    onClick={messagePopUp}
                  >
                    Generate Two-Pager
                  </Button>
                </>
              )
            ) : (
              <>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#C5C5D6",
                    margin: 0,
                    borderRadius: 0,
                    textTransform: "capitalize",
                  }}
                  disabled
                >
                  {loading ? "Uploading file.." : <>Generate Two-Pager</>}
                </Button>
              </>
            )}
          </Grid>
        </Grid>
        <input
          type="file"
          id="file-upload"
          accept=".pdf"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </Box>

      <ToastContainer />

      <Modal open={open} onClose={handleClose}>
        <Paper
          sx={{
            position: "absolute",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Grid container justifyContent="flex-end">
            <HighlightOffIcon
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <InsertDriveFileIcon style={{ color: "#292F62", fontSize: 40 }} />
            <div
              {...getRootProps()}
              style={{
                border: "2px dashed #292F62",
                padding: "20px",
                cursor: "pointer",
                textAlign: "center",
                marginTop: "20px",
              }}
            >
              <input {...getInputProps()} />
              <Typography
                variant="body1"
                gutterBottom
                style={{ fontSize: "13px" }}
              >
                Drag and drop a PDF file here or click to select a file.
              </Typography>
            </div>
            <Typography variant="h6" gutterBottom style={{ fontSize: "13px" }}>
              or
            </Typography>
            <Grid style={{ marginBottom: "5%" }}>
              <label htmlFor="file-upload">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  style={{ backgroundColor: "#292F62", fontSize: "12px" }}
                >
                  <InsertDriveFileOutlinedIcon />
                  Choose your files
                </Button>
              </label>
            </Grid>
            <Grid>
              {selectedFile && (
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ fontSize: "13px" }}
                >
                  File Name: {selectedFile.name}
                </Typography>
              )}
            </Grid>
            {selectedFile && dealCloudId && (
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: "#292F62", fontSize: "12px" }}
                onClick={handleUpload}
              >
                Upload
              </Button>
            )}
          </Grid>
        </Paper>
      </Modal>

      {cardShow && (
        <Grid style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Card
            style={{
              borderRadius: 0,
              margin: "30px 30px 0",
              width: "100%"
            }}
          >
            <Grid style={{ position: "relative" }}>
              <img
                src={sideImage}
                style={{ width: "72px", height: "73px" }}
                hidden
              />
              {activeStep <= 14 ? (
                <img
                  src={refreshImage}
                  style={{
                    width: "57px",
                    height: "52px",
                    position: "absolute",
                    left: "10px",
                    top: "10px",
                  }}
                  onClick={handleNext}
                  disabled={activeStep === steps.length - 1}
                  hidden
                />
              ) : (
                <></>
              )}
              <ul className="legends">
                <li>
                  <span className="dot aqua"></span> {"Uploaded"}
                </li>
                <li>
                  <span className="dot grey"></span> {"Queued"}
                </li>
                <li>
                  <span className="dot yellow"></span> {"In Progress"}
                </li>
                <li>
                  <span className="dot green"></span> {"Completed"}
                </li>
                <li>
                  <span className="dot orange"></span> {"Cancelled"}
                </li>
                <li>
                  <span className="dot red"></span> {"Failed"}
                </li>
              </ul>
            </Grid>
            <Grid style={{ display: "flex", flexDirection: "row-reverse" }}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: isCancelDisabled
                    ? "rgba(0, 0, 0, 0.12)"
                    : "#292F62",
                  // backgroundColor: "rgba(0, 0, 0, 0.12)",
                  margin: "20px",
                  fontSize: "14px",
                  textTransform: "capitalize",
                  borderRadius: 0,
                }}
                onClick={handleOpenCanclePopUP}
                disabled={isCancelDisabled}
              // disabled={true}
              >
                Cancel
              </Button>
            </Grid>

            <Modal open={openCanclePopUp} onClose={handleCloseCanclePopUP}>
              <Paper
                sx={{
                  position: "absolute",
                  width: 400,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <Grid style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <HighlightOffIcon onClick={handleCloseCanclePopUP} />
                </Grid>
                <Grid style={{ textAlign: "center" }}>
                  <Grid>
                    <ErrorRoundedIcon sx={{ color: "#FF4E28" }} />
                  </Grid>

                  <Typography
                    variant="body1"
                    gutterBottom
                    style={{ fontSize: "13px" }}
                  >
                    Are you sure you want to cancel?
                  </Typography>

                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#292F62",
                      fontSize: "12px",
                      marginTop: "1%",
                    }}
                    onClick={genrationCancelTrigger}
                  >
                    Yes
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#292F62",
                      fontSize: "12px",
                      marginTop: "1%",
                      marginLeft: "2%",
                    }}
                    onClick={handleCloseCanclePopUP}
                  >
                    No
                  </Button>
                </Grid>
              </Paper>
            </Modal>

            <Grid style={{ padding: "20px 30px" }}></Grid>
            <Typography
              variant="body1"
              style={{ textAlign: "center", margin: "0 0 40px 0" }}
            >
              {"Two Pager Section-Wise Generation Status"}
            </Typography>

            <Stack sx={{ width: "100%" }} spacing={4}>
              <Stepper
                alternativeLabel
                activeStep={""}
                connector={<QontoConnector />}
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel
                      StepIconComponent={QontoStepIcon}
                      style={{ fontSize: 12 }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Stack>

            <Grid
              style={{
                display: "flex",
                justifyContent: "end",
                marginRight: "2%",
              }}
            >
              {(statusData["pager_status"] === "Completed" &&
                statusData["insight_status"] === "Failed") ||
                (statusData["pager_status"] === "Completed" &&
                  statusData["insight_status"] === "Completed") ? (
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#292F62",
                    margin: "40px 0 25px",
                    textTransform: "capitalize",
                  }}
                  onClick={() => handleViewGenrateFile()}
                >
                  VIEW GENERATED TWO-PAGER
                </Button>
              ) : (
                <></>
              )}
            </Grid>
          </Card>
        </Grid>
      )}

      <div ref={bottomRef}></div>
      <div class="divider"></div>
      <Typography style={{ padding: 30, fontSize: 14, textAlign: "center" }}>
        &#169; All Rights Reserved (2025) Veritas Capital : Release Version
        :&nbsp;
        <Typography
          component="span"
          style={{ cursor: "pointer", color: "#33348e", fontSize: 14 }}
          onClick={() => {
            window.open(`${window.location.origin}/release_notes`, "_blank");
          }}
        >
          {releaseVersion}
        </Typography>
      </Typography>
      {console.log(isAPILoading)}
      {isAPILoading && (
        <Box
          className="loaderPage"
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 2000,
          }}
        >
          <RotateRightIcon
            color="inherit"
            fontSize="large"
            sx={{ animation: "spin 2s infinite ease-in-out" }}
          />
        </Box>
      )}
    </>
  );
}
