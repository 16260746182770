// Routes.js

import React, { useEffect, useCallback } from "react";
import {
  Routes,
  Route,
  useParams,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Dashboard from "./Components/Dashboard";
import SignIn from "./Components/Login";
import HttpError from "./Components/HttpError";
import DocumnetEditPage from "./Components/documnetEditPage/DocumnetEditPage";
import CustonTab from "./Components/documnetEditPage/CustonTab";
import Final2Pager from "./Components/Final2Pager/Final2Pager";
import PrivateRoute from "./PrivateRoute";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "./azure/authConfig";
import { login } from "../src/Components/Login/service";
import ReleaseNotes from "./Components/common/ReleaseNotes";
import AdminPanel from "./Components/AdminPanel";
import ViewPortfolioList from "./Components/AdminPanel/ViewPortfolioList";
import UserManagement from "./Components/AdminPanel/UserManagement";
import AddPortfolioForm from "./Components/AdminPanel/AddPortfolioForm.js";
import AddUserForm from "./Components/AdminPanel/UserManagement/AddUserForm.js";
import FailedGenerations from "./Components/AdminPanel/FailedGenerations.js";
import Feedback from "./Components/AdminPanel/Feedback.js";

const AppRoutes = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  const initializeSession = useCallback(async () => {
    await instance.initialize();
  }, [instance]);

  const callLoginApi = async (userLoginInfo) => {
    login(userLoginInfo.userEmail, userLoginInfo.access_token);
  };

  const getLoginInfo = async () => {
    const activeAccount = instance.getActiveAccount();
    if (!activeAccount) {
      console.error("No active account! Please ensure you are logged in.");
      return;
    }

    try {
      const response = await instance.acquireTokenSilent({
        account: activeAccount,
        scopes: ["User.Read"],
      });

      const userEmail = response.account.idTokenClaims.email;

      const userLoginInfo = {
        token_type: "Bearer",
        scope: (response.scopes || []).join(" "),
        expires_in: Math.floor(
          (new Date(response.expiresOn) - new Date()) / 1000
        ),
        ext_expires_in: Math.floor(
          (new Date(response.extExpiresOn) - new Date()) / 1000
        ),
        access_token: response.accessToken || "",
        refresh_token: response.refreshToken || "",
        refresh_token_expires_in: response.refreshTokenExpiresOn || "",
        id_token: response.idToken || "",
        client_info: response.clientInfo || "",
        userEmail: userEmail || "",
      };

      localStorage.setItem("userLoginInfo", JSON.stringify(userLoginInfo));
      await callLoginApi(userLoginInfo);
      // navigate("/dashboard");
    } catch (error) {
      console.error("Error retrieving user email:", error);
    }
  };

  useEffect(() => {
    initializeSession();
    const storedUserInfo = localStorage.getItem("userLoginInfo");

    if (
      isAuthenticated &&
      !instance.getActiveAccount() &&
      accounts.length > 0
    ) {
      instance.setActiveAccount(accounts[0]);
    }

    if (isAuthenticated && instance.getActiveAccount()) {
      getLoginInfo();
    }
    // if (storedUserInfo) {
    //   navigate("/dashboard");
    //   return;
    // }
  }, [isAuthenticated, accounts, initializeSession, instance]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route
          path="/release_notes"
          element={
            <PrivateRoute>
              {" "}
              <ReleaseNotes />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />

        <Route
          path="/admin"
          element={
            <PrivateRoute>
              <AdminPanel />
            </PrivateRoute>
          }
        >
          <Route
            index
            element={
              <PrivateRoute>
                <ViewPortfolioList />
              </PrivateRoute>
            }
          />

          <Route
            path="portco-kb-management"
            element={
              <PrivateRoute>
                <ViewPortfolioList />
              </PrivateRoute>
            }
          />

          <Route
            path="portco-kb-management/:dealId"
            element={
              <PrivateRoute>
                <AddPortfolioForm />
              </PrivateRoute>
            }
          />

          <Route
            path="user-management"
            element={
              <PrivateRoute>
                <UserManagement />
              </PrivateRoute>
            }
          />

          <Route
            path="user-management/:userId"
            element={
              <PrivateRoute>
                <AddUserForm />
              </PrivateRoute>
            }
          />

          <Route
            path="failed-generations"
            element={
              <PrivateRoute>
                <FailedGenerations />
              </PrivateRoute>
            }
          />

          <Route
            path="feedback"
            element={
              <PrivateRoute>
                <Feedback />
              </PrivateRoute>
            }
          />
        </Route>

        <Route
          path="/create-new-doc"
          element={
            <PrivateRoute>
              <DocumnetEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/create-new-doc/:userId"
          element={
            <PrivateRoute>
              <DocumnetEditPageInprogress />
            </PrivateRoute>
          }
        />
        <Route
          path="/view-generate-2pager/:userId"
          element={
            <PrivateRoute>
              <CustonTabWrapper />
            </PrivateRoute>
          }
        />
        <Route
          path="/final-2pager/:userId"
          element={
            <PrivateRoute>
              <Final2PagerWrapper />
            </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={<HttpError code={404} message="Nothing to see here!" />}
        />
      </Routes>
    </>
  );
};

const CustonTabWrapper = () => {
  const { userId } = useParams();
  return <CustonTab userId={userId} />;
};

const DocumnetEditPageInprogress = () => {
  const { userId } = useParams();

  return <DocumnetEditPage userId={userId} />;
};

const Final2PagerWrapper = () => {
  const { userId } = useParams();

  return <Final2Pager userId={userId} />;
};

export default AppRoutes;
