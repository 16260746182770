import React, { useState, useEffect, useRef } from "react";
import Header from "../drawer/Header";
import Select from '@mui/material/Select';
import pagerIcon from "../../Assets/Icons/pager-icon.svg";
import Typography from '@mui/material/Typography';
import { json, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Menu from '@mui/material/Menu';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import Box from '@mui/material/Box';
import jsPDF from 'jspdf';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver';
import { view2PagerFinalLists, getFile, getPDFFile } from "./service"
import KeyInformationTable from "../documnetEditPage/Page/KeyInformationTable.js"
import BusinessDescription from "../documnetEditPage/Page/BusinessDescription.js"
import SituationOverview from "../documnetEditPage/Page/SituationOverview.js"
import OwnershipManagement from "../documnetEditPage/Page/OwnershipManagement.js"
import PriliminaryInvestment from "../documnetEditPage/Page/PriliminaryInvestment.js"
import FinancialOverview from "../documnetEditPage/Page/FinancialOverview.js";
import KeyMeritsAndConsideration from "../documnetEditPage/Page/KeyMeritsConsideration.js"
import PastSimilarLooks from "../documnetEditPage/Page/PastSimilarLooks.js"
import PublicComps from "../documnetEditPage/Page/PublicComps.js"
import OtherRelevantInfo from "../documnetEditPage/Page/OtherRelevantInfo.js"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import 'jspdf-autotable';
import { Grid, IconButton } from '@mui/material';
import Divider from '@mui/material/Divider';
import html2canvas from 'html2canvas'
import { useParams } from "react-router-dom";
import UploadFileTwoToneIcon from '@mui/icons-material/UploadFileTwoTone';
import SVGIcon from "../../Assets/Icons/download-financial-csv-icon.svg"
import CustomTable from "./CustomTable"
import "./index.css"
import { useLocation } from 'react-router-dom';
import { updateBTEV, updateBTEVID } from "../redux/reducer/twoPagerSlice";
import { useDispatch } from "react-redux";
import CustomBreadcrumb from "../drawer/CustomBreadcrumb";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

export default function Final2Pager() {
    const location = useLocation();
    const navigate = useNavigate();
    const urlParams = useParams();
    const dispatch = useDispatch();
    const params = urlParams.userId
    const paramsVersion = params.split('_').pop();

    const [releaseVersion, setReleaseVersion] = useState("NA");
    const [rowsData, setRowsData] = useState([]);
    const [informationData, setInformationData] = useState([]);
    const [businessDesc, setBusinessDesc] = useState({
        companyName: "",
        data: []
    })
    const [situationOverviewTabData, setSituationOverviewTabData] = useState({
        ownershipManagement: [],
    })
    const [ownership, setOwnership] = useState({
        ownershipManagement: [],
    })
    const [preliminaryInvestmentTabData, setPreliminaryInvestmentTabData] = useState({
        preliminaryInvestment: [],
    })
    const [financialOverview, setFinancialOverview] = useState({
        param: [],
        fileName: [],
    })

    const [meritsAndConsideration, setMeritsAndConsideration] = useState({
        merits: [],
        consideration: [],
        meritsHeader: [],
        considerationHeader: [],
    })
    const [companyName, setCompanyName] = useState();
    const [publicComps, setPublicComps] = useState([]);
    const [otherRelData, setOtherRelData] = useState([]);
    const [pastSimilarLoks, setPastSimilarLooks] = useState([]);

    const FinalPageReviewBtn = styled(Button)(({ theme }) => ({
        backgroundColor: "#fff",
        '&:hover': {
            backgroundColor: "#fff",
        },
        padding: "12px",
    }));

    const commonStyle = {
        fontSize: "18px",
        margin: "40px",
        fontWeight: "bold",
        color: "#292F62"
    };

    const handleViewGenrateFile = () => {
        // navigate(-1);
        navigate(`/view-generate-2pager/${params}`, { state: { doc: companyName } });
        dispatch(updateBTEV(true))
        dispatch(updateBTEVID(params))
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#F0F2F5",
            color: "#666F8D",
            fontWeight: 'bold',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            color: "#666F8D"
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(even)': {
            backgroundColor: "#f7f8f8",
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));


    const StyledSelect = styled(Select)(({ theme }) => ({
        '& select': {
            borderColor: '#DFDFDF', // Set the border color to light grey
            borderWidth: '1px', // Set the border width
        },
    }));

    const ColorButton = styled(Button)(({ theme }) => ({
        backgroundColor: "#7AD167",
        '&:hover': {
            backgroundColor: "#7AD167",
        },
        padding: "12px",
    }));

    const parseKeyInformation = (data) => {
        if (data && data[0] && data[0].bullets) {
            const result = data[0].bullets.map(item => ({
                name: item?.name,
                value: item?.values[0]?.result || ''
            }));
            setInformationData(result);
        }
    };

    const transformBusinessDescData = param => {
        const data = param.bullets
        const transformBullets = (bullets) => {
            return bullets.map(bullet => {
                let bulletObject = {
                    name: bullet.name,
                    description: bullet?.values?.length > 0 ? bullet?.values[0]?.result : null,
                };

                if (bullet.sub_bullets && bullet.sub_bullets?.length > 0) {
                    bulletObject.subDescription = bullet.sub_bullets.map(subBullet => ({
                        name: subBullet.name,
                        description: subBullet?.values?.length > 0 ? subBullet?.values[0]?.result : null
                    }));
                }

                return bulletObject;
            });
        };

        const transformedBullets = transformBullets(data);
        return transformedBullets
    }

    const parseDataFormat = (data) => {

        const formattedBullets = data.bullets.map(bullet => {
            return {
                name: bullet?.name,
                description: bullet?.values?.map(value => value?.result)
            };
        });
        return formattedBullets
    }

    const parseMeritsAndConsiderations = (data) => {

        const formattedBullets = data?.map(item => ({
            name: item?.name,
            description: item?.values.map(value => value?.result).join(" ")
        }));
        return formattedBullets
    }

    function formatDate(inputDate) {
        // Create a new Date object from the input date string
        let date = new Date(inputDate);

        // Extract components of the date
        let month = date.getMonth() + 1; // getMonth() returns zero-based month
        let day = date.getDate();
        let year = date.getFullYear();

        // Ensure month and day are formatted to have leading zeros if necessary
        if (month < 10) {
            month = "0" + month;
        }
        if (day < 10) {
            day = "0" + day;
        }

        // Concatenate the formatted date in MM.DD.YYYY format
        return month + "." + day + "." + year;
    }
    useEffect(() => {
        setReleaseVersion(JSON.parse(localStorage.getItem("releaseVersion")))
        localStorage.setItem("section", 0);
        localStorage.setItem("acceptedAll", "false");
    }, [releaseVersion]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await view2PagerFinalLists(params);
                setCompanyName(response.company_name)
                if (response && response.data) {
                    if (response.data[0]) {
                        setRowsData(response.data);
                        parseKeyInformation(response.data);

                    }


                    // if (response.data.length > 1 && response.data[1].name) {
                    //     const result = transformBusinessDescData(response?.data[1])
                    //     setBusinessDesc({
                    //         data: result, companyName: response?.data[1]?.name
                    //     })
                    //     console.log(result,"result11111111111111")
                    // }

                    if (response.data.length > 1 && response.data[5].name) {
                        const OwnershipManagementdata = parseDataFormat(response?.data[5])
                        setOwnership({ OwnershipManagementdata })

                    }




                    if (response.data.length > 1 && response.data[3].name) {
                        const preliminaryInvestment = parseDataFormat(response?.data[3])
                        setPreliminaryInvestmentTabData({ preliminaryInvestment })
                    }

                    if (response.data.length > 1 && response.data[6].name) {
                        const situationOverviewdata = parseDataFormat(response?.data[6])
                        setSituationOverviewTabData({ situationOverviewdata })
                    }

                    if (response.data.length > 1 && response.data[7].name) {

                        const array = response.data[7].bullets[0]?.values[0]?.result
                        setPastSimilarLooks(array)
                    }

                    if (response.data.length > 1 && response.data[8].name) {

                        const data = response.data[8]?.bullets[0]?.values[0]?.result

                        setPublicComps(data)
                    }


                    if (response.data.length > 1 && response.data[9].name) {

                        const array = response.data[9]?.bullets.map(bullet => ({
                            title: bullet.name,
                            subtitle: bullet.values[0].result
                        }));
                        setOtherRelData(array)


                    }
                    // if (response.data.length > 1 && response.data[5].name) {
                    //     setFinancialOverview(response.data[5])
                    // }
                    // if(response.data.length > 1 && response.data[8].name ){

                    //         const response = await parseDataFormat(response?.data[8])
                    //         console.log(response,"kkkkkkkkkkkkkk")
                    //         const resultArray = response.data[8]?.bullets.map(bullet => ({
                    //             title: bullet.name,
                    //             subtitle: bullet.values[0].result
                    //         }));
                    //         setOtherRelData(resultArray)
                    // }
                    if (response.data.length > 1 && response.data[1].name) {
                        const preliminaryInvestment = transformBusinessDescData(response?.data[1])
                        setBusinessDesc({
                            data: preliminaryInvestment, companyName: response.company_name
                        })
                    }

                    if (response.data.length > 1 && response.data[2].name) {

                        const merits = parseMeritsAndConsiderations(response.data[2]?.bullets[0]?.sub_bullets)
                        const consideration = parseMeritsAndConsiderations(response.data[2]?.bullets[1]?.sub_bullets)
                        const keyHeaders = (response?.data[2]?.bullets[0]?.name)
                        const considerationHeaders = (response?.data[2]?.bullets[1]?.name)
                        setMeritsAndConsideration({
                            merits,
                            consideration,
                            keyHeaders,
                            considerationHeaders
                        })

                    }
                    if (response.data.length > 1 && response.data[4].name) {
                        // const response = await view2PagerLists(5,params)
                        const param = params
                        const fileName = ('finacial_overview')
                        setFinancialOverview({
                            param,
                            fileName,
                        })
                    }

                }
            } catch (error) {
                console.log("error:", error);
            }
        };

        fetchData();
    }, []);
    const contentRef = useRef();

    const downloadFile = async (param) => {
        try {
            const response = await getFile(param);
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            // let cim_id=String(jsonData).split("_");
            // Create an anchor element and trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${location.state.fileName}.doc`);
            document.body.appendChild(link);
            link.click();
            link.remove();

        } catch (error) {
            console.error("Error fetching generation status:", error);

        }
    };

    const downloadPDFFile = async (param) => {
        try {
            const response = await getPDFFile(param);
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${location.state.fileName}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            // window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error fetching generation status:", error);

        }
    };

    return (
        <>
            <Header />
            <CustomBreadcrumb showBack={true} title={companyName}
                params={params} companyName={companyName} subTitle={location.state.version} view={"2Pager"}
            />
            <section style={{ display: "flex", justifyContent: 'space-between', margin: "0 25px 0" }}>
                <article style={{ display: "flex", alignItems: "end" }}>
                    <article style={{ display: "flex", columnGap: "10px", alignItems: "end" }}>
                        {/* <img src={pagerIcon} alt="pager-icon" style={{ height: "18px", marginRight: "10px" }} /> */}
                        {/* <Typography style={{ color: "#19213D", fontSize: "16px", fontWeight: 'bold' }}>{companyName}</Typography> */}
                    </article>
                </article>
                <article style={{ display: "flex" }}>
                    <aside>
                        <IconButton
                            style={{ padding: 0 }}
                            onClick={() => downloadPDFFile(params)}
                        >
                            <PictureAsPdfIcon style={{fontSize: 35}}/>
                        </IconButton>
                    </aside>
                    <aside>
                        <IconButton
                            style={{ padding: 0 }}
                            onClick={() => downloadFile(params)}
                        >
                            <InsertDriveFileIcon style={{fontSize: "35px"}}/>
                        </IconButton>
                    </aside>
                    {/* <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                            <React.Fragment>
                                <Button variant="contained" style={{
                                    color: '#292f62', backgroundColor: 'white',
                                    fontSize: '12px', borderRadius: 0,
                                    textTransform: "capitalize"
                                }} {...bindTrigger(popupState)}>
                                    Export <KeyboardArrowDownOutlinedIcon />
                                </Button>
                                <Menu {...bindMenu(popupState)}>
                                    <MenuItem onClick={() => downloadPDFFile(params)}>PDF</MenuItem>
                                    <MenuItem onClick={() => downloadFile(params)}>Word</MenuItem>
                                </Menu>
                            </React.Fragment>
                        )}
                    </PopupState> */}
                    {/* <Button variant="contained" style={{ color: 'black', background: 'white', fontSize: '10px', padding: "7px 25px" }}
                        onClick={() => handleViewGenrateFile()}>
                        <KeyboardArrowLeftIcon style={{ fontSize: "22px" }} />
                        Back
                    </Button> */}
                </article>
            </section>
            <Box
                sx={{
                    borderRadius: '0', bgcolor: 'background.paper', margin: "10px 30px", display: 'flex', flexDirection: 'column', justifyContent: 'center'
                }}
                ref={contentRef}
                id="pdf-content"
            >
                <Grid>
                    <Typography style={commonStyle}>Key Information</Typography>
                </Grid>
                <Divider style={{ background: "#E5E5E5", margin: "30px", height: "1px" }} />
                <KeyInformationTable rows={informationData} />

                <Grid>
                    <Typography style={commonStyle}>Business Description</Typography>
                </Grid>
                <Divider style={{ background: "#E5E5E5", margin: "30px", height: "1px" }} />
                <BusinessDescription data={businessDesc} section={"final2pager"} />

                <Grid>
                    <Typography style={commonStyle}>Merits & Considerations</Typography>
                </Grid>
                <Divider style={{ background: "#E5E5E5", margin: "30px", height: "1px" }} />
                <KeyMeritsAndConsideration data={meritsAndConsideration} section={"final2pager"} />

                <Grid>
                    <Divider style={{ background: "#E5E5E5", margin: "30px", height: "1px" }} />
                    <Typography style={commonStyle}>Preliminary Investment Thesis</Typography>
                </Grid>
                <Divider style={{ background: "#E5E5E5", margin: "30px", height: "1px" }} />
                <PriliminaryInvestment data={preliminaryInvestmentTabData} section={"final2pager"} />

                <Grid>
                    <Divider style={{ background: "#E5E5E5", margin: "30px", height: "1px" }} />
                    <Typography style={commonStyle}>Financial Overview</Typography>
                </Grid>
                <Divider style={{ background: "#E5E5E5", margin: "30px", height: "1px" }} />
                <FinancialOverview param={params}
                    fileName={`${companyName
                        }_Financial Overview_${formatDate(new Date())}_${paramsVersion
                        }.xlsx`}
                />

                <Grid>
                    <Divider style={{ background: "#E5E5E5", margin: "30px", height: "1px" }} />
                    <Typography style={commonStyle}>Ownership / Management</Typography>
                </Grid>
                <Divider style={{ background: "#E5E5E5", margin: "30px", height: "1px" }} />
                <OwnershipManagement data={ownership} />

                <Grid>
                    <Divider style={{ background: "#E5E5E5", margin: "30px", height: "1px" }} />
                    <Typography style={commonStyle}>Situation Overview / Next Steps</Typography>
                </Grid>
                <Divider style={{ background: "#E5E5E5", margin: "30px", height: "1px" }} />
                <SituationOverview data={situationOverviewTabData} />

                <Grid className="sectionBody">
                    <Typography className="sectionHeading" component="span"> <Typography className="sectionNumber" component="span">IX.</Typography>
                        Past Similar Looks </Typography>
                    {pastSimilarLoks.length > 0 && <CustomTable data={pastSimilarLoks} section={"pastSimilarLooks"} />}
                </Grid>

                <Grid className="sectionBody">
                    <Typography className="sectionHeading" component="span"> <Typography className="sectionNumber" component="span">X.</Typography>
                        Public Comps </Typography>
                    {publicComps.length > 0 && <CustomTable data={publicComps} section={"publicComps"} />}
                </Grid>

                <Grid>
                    <Divider style={{ background: "#E5E5E5", margin: "30px", height: "1px" }} />
                    <Typography style={commonStyle}>Other Relevant Information  </Typography>
                </Grid>
                <Divider style={{ background: "#E5E5E5", margin: "30px", height: "1px" }} />
                <OtherRelevantInfo data={otherRelData} section={"final2pager"} />
            </Box>




            {/* <article style={{ marginLeft: "48px", display: "flex", justifyContent: 'end', margin: '1%' }}>
                <ColorButton
                    variant="contained"
                    style={{ textTransform: 'none', height: "30px", marginRight: "12px" }}
                    disabled={true}
                >
                    <Typography className="create-new-doc-btn-text"> Export Financial to Excel</Typography>
                </ColorButton>
                <ColorButton
                    variant="contained"
                    style={{ textTransform: 'none', height: "30px", marginRight: "12px" }}
                    onClick={() => downloadPDFFile(params)}
                // disabled={true}
                >
                    <Typography className="create-new-doc-btn-text"> Export to PDF</Typography>
                </ColorButton>
                <ColorButton
                    variant="contained"
                    style={{ textTransform: 'none', height: "30px", marginRight: "12px" }}
                    onClick={() => downloadFile(params)}
                // disabled={true}
                >
                    <Typography className="create-new-doc-btn-text"> Export to Word </Typography>
                </ColorButton>


            </article> */}
            <div class="divider"></div>
            <Typography style={{ padding: 20, fontSize: 12, textAlign: "center" }}>
                &#169; All Rights Reserved (2025) Veritas Capital : Release Version
                :&nbsp;
                <Typography
                    component="span"
                    style={{ cursor: "pointer", color: "#33348e", fontSize: 14 }}
                    onClick={() => {
                        window.open(`${window.location.origin}/release_notes`, "_blank");
                    }}
                >
                    {releaseVersion}
                </Typography>
            </Typography>
        </>
    );
}
