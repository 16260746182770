import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Delete as DeleteIcon,
  CheckCircle as SuccessIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";
import {
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tooltip,
} from "@mui/material";
import "./style.css";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import DownloadIcon from "@mui/icons-material/Download";
import DriveFolderUploadRoundedIcon from "@mui/icons-material/DriveFolderUploadRounded";
import { deletePortfolio, downloadPortfolio } from "./service";
import { environment } from "../../environment";

function formatFileSize(sizeInBytes) {
  const MB = 1024 * 1024;
  let sizeInMB = sizeInBytes / MB;
  return `${sizeInMB.toFixed(1)} MB`;
}

function UploadFiles(props) {
  const {
    clickable = true,
    getOldPortfolioId,
    getPortfolioId,
    getRestoreFiles,
    dealData,
    dealId,
  } = props;

  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState({});
  const [uploadStatus, setUploadStatus] = useState({});
  const [fileSizes, setFileSizes] = useState({});
  const [uploadedSizes, setUploadedSizes] = useState({});
  const [alreadyUploaded, setAlreadyUploaded] = useState([]);
  const [existingFiles, setExistingFiles] = useState(dealData?.docs);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [docToDelete, setDocToDelete] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);
  const buildUrl = (path) => {
    return environment.baseUrl + path;
  };
  const getUserDetails = () => {
    const response = JSON.parse(localStorage?.getItem("userLoginInfo"));
    const data = {
      email_id: response?.userEmail,
      access_token: response?.access_token,
    };

    const options = {
      headers: data,
    };
    return options?.headers;
  };

  const handleFileChange = (e) => {
    const newFiles = e.target.files;
    let tempSizes = {};
    let tempFiles = [...files];

    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ];

    // Loop through selected files and append them to the existing list
    [...newFiles].forEach((file) => {
      if (
        allowedTypes.includes(file?.type) &&
        !tempFiles.find((f) => f?.name === file?.name)
      ) {
        tempFiles.push(file);
        tempSizes[file?.name] = file?.size; // Store size in bytes
      } else if (!allowedTypes.includes(file?.type)) {
        toast.warn("Invalid file type. Please upload a valid document.");
      }
    });

    // Update state with new file list and sizes
    setFiles(tempFiles);
    setFileSizes((prevSizes) => ({ ...prevSizes, ...tempSizes }));

    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  // Handle the file upload
  const handleUpload = async () => {
    setIsUploading(true);
    const tempProgress = { ...progress };
    const tempUploadedSizes = { ...uploadedSizes };

    const filesToUpload = files.filter(
      (file) => !alreadyUploaded.includes(file?.name)
    );
    const formData = new FormData();

    // Append all files to the FormData
    filesToUpload.forEach((file) => {
      formData.append("additional_files", file);
    });

    const config = {
      onUploadProgress: (progressEvent) => {
        const total = progressEvent.total;
        const loaded = progressEvent.loaded;
        const percentCompleted = Math.round((loaded * 100) / total);

        // Update progress for the combined upload
        setProgress({ combined: percentCompleted });

        // Update uploaded sizes for all files being uploaded
        tempUploadedSizes.total = loaded;
        setUploadedSizes(tempUploadedSizes);
      },
    };

    try {
      setIsLoading(true);
      const userHeaders = getUserDetails();
      const response = await axios.post(
        buildUrl(
          `portco_api/portfolio/upload_portfolio?company_name=${dealData?.companyName}&deal_cloud_id=${dealId}`
        ),
        formData,
        {
          headers: {
            ...userHeaders, // Spread the user headers here
            "Content-Type": "multipart/form-data",
          },
        }
      );
      filesToUpload.forEach((file) => {
        setUploadStatus((prev) => ({ ...prev, [file?.name]: "success" }));
        setAlreadyUploaded((prev) => [...prev, file?.name]); // Mark as uploaded
      });
      getPortfolioId(response?.data?.data?.portfolio_id);
      console.log(response);

      if (response?.data?.status !== "success") {
        throw new Error("Network response was not ok");
      }
      if (response?.data?.status === "success") {
        toast.success("File(s) uploaded successfully!");
      }
    } catch (error) {
      filesToUpload.forEach((file) => {
        setUploadStatus((prev) => ({ ...prev, [file?.name]: "error" }));
      });
      console.error("Error uploading files:", error);
      toast.error(`Upload failed: ${error.message}`);
    } finally {
      setIsLoading(false);
      setIsUploading(false);
    }
  };

  // Handle the remove file request
  const handleRemove = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));

    setUploadStatus((prevStatus) => {
      const newStatus = { ...prevStatus };
      delete newStatus[fileName];
      return newStatus;
    });

    setProgress((prevProgress) => {
      const newProgress = { ...prevProgress };
      delete newProgress[fileName];
      return newProgress;
    });

    setFileSizes((prevSizes) => {
      const newSizes = { ...prevSizes };
      delete newSizes[fileName];
      return newSizes;
    });

    setUploadedSizes((prevUploaded) => {
      const newUploaded = { ...prevUploaded };
      delete newUploaded[fileName];
      return newUploaded;
    });

    setAlreadyUploaded((prevUploaded) =>
      prevUploaded.filter((file) => file !== fileName)
    );

    // Clear the file input only if the deleted file was the last one
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };
  const handleUplodedRemove = async () => {
    try {
      setIsLoading(true);
      const response = await deletePortfolio(dealId, docToDelete);
      if (response) {
        setExistingFiles(response?.data?.supplementary);
        getOldPortfolioId(response?.data?.supplementary);
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 5000, // Close the notification after 5 seconds
        });
      }
      // Clear the file input only if the deleted file was the last one
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
    } catch (response) {
      toast.error(response?.status, {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setIsLoading(false);
      setOpenDialog(false);
      setDocToDelete(null);
    }
  };

  const handleDownload = async (filename, portfolioId) => {
    try {
      const newFileName = filename.replace(/\.[^/.]+$/, `.${"pdf"}`);
      const response = await downloadPortfolio(portfolioId);
      if (response) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${newFileName}`);
        document.body.appendChild(link);
        link.click();
        link.remove();

        toast.success("File downloaded successfully!", {
          position: "top-right",
          autoClose: 5000, // Close the notification after 5 seconds
        });
      }
    } catch (response) {
      toast.error(response?.status, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  const handleOpenDialog = (id) => {
    setDocToDelete(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDocToDelete(null);
  };

  const StatusCircle = ({ status, value }) => {
    const colorMap = { green: "#00b38a", red: "#ea324c", amber: "#f2ac42" };
    return (
      <Tooltip title={value} arrow>
        <div
          style={{
            backgroundColor: colorMap[status] || "#0000001f",
            width: "22px",
            height: "22px",
            borderRadius: "50%",
            margin: "0 10px",
          }}
        ></div>
      </Tooltip>
    );
  };

  const handleCheckboxChange = (id) => {
    setSelectedFiles((prevSelectedFiles) => {
      if (prevSelectedFiles.includes(id)) {
        return prevSelectedFiles.filter((item) => item !== id);
      } else {
        return [...prevSelectedFiles, `'${id}'`];
      }
    });
  };

  useEffect(() => {
    setExistingFiles(dealData?.docs);
  }, []);

  React.useEffect(() => {
    getRestoreFiles(selectedFiles);
  }, [selectedFiles, getRestoreFiles]);

  return (
    <>
      <div
        className="uploadArea"
        style={{
          border: files?.length > 0 ? "1px solid #ddd" : 0,
          padding: files?.length > 0 ? 10 : "10px 0",
          borderRadius: 10,
        }}
      >
        <div className="customSelect">
          <input
            type="file"
            id="files-upload"
            multiple
            onChange={(e) => handleFileChange(e)}
            className="fileInput"
            disabled={!clickable || isUploading}
            accept=".pdf,.doc,.docx,.ppt,.pptx"
            ref={fileInputRef}
          />
          <label
            htmlFor="files-upload"
            className="fileInputLabel"
            style={{
              cursor: !clickable || isUploading ? "not-allowed" : "pointer",
              backgroundColor:
                !clickable || isUploading ? "#c7c7c7" : "#7c7c7c",
              border: "2px",
              color:
                !clickable || isUploading
                  ? "rgba(0, 0, 0, 0.26)"
                  : "rgb(255, 255, 255)",
              marginBottom: 10,
            }}
          >
            Select File(s)
          </label>
        </div>

        <div className="uploadSection">
          {/* Existing Files List */}

          <div className="newFilesWrapper">
            <div className="newFilesHeader">
              <div className="headerItem">Upload Status</div>
              <div className="headerItem" style={{ width: "500px" }}>
                Document Name
              </div>
              <div className="headerItem">Uploaded Date</div>
              <div
                className="headerItem"
                style={{
                  textAlign: "center",
                }}
              >
                Size
              </div>
              <div
                className="headerItem"
                style={{
                  textAlign: "center",
                }}
              >
                Document Processing Status
              </div>
              <div
                className="headerItem"
                style={{
                  textAlign: "center",
                }}
              >
                Actions
              </div>
              <div
                className="headerItem"
                style={{
                  textAlign: "center",
                }}
              >
                Restore
              </div>
            </div>

            <ul className="newFilesList">
              {existingFiles?.length > 0 &&
                existingFiles?.map((file, index) => (
                  <li key={index} className="newFileItem">
                    <div className="uploadStatus">
                      <SuccessIcon color="success" />
                    </div>
                    <div
                      className="newFileName"
                      title={file?.document_name}
                      style={{ width: "500px" }}
                    >
                      {file?.document_name}
                    </div>
                    <div className="newFileDate">{file?.uploaded_date}</div>
                    <div
                      className="newFileSize"
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      {file?.size}
                    </div>
                    <div
                      className="newFileSize"
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      {file?.processing_status === "Completed" ? (
                        <StatusCircle
                          status="green"
                          value={file?.processing_status}
                        />
                      ) : file?.processing_status === "Failed" ? (
                        <StatusCircle
                          status="red"
                          value={file?.processing_status}
                        />
                      ) : file?.processing_status === "In Progress" ? (
                        <StatusCircle
                          status="amber"
                          value={file?.processing_status}
                        />
                      ) : (
                        <StatusCircle status="" value={"Inactive"} />
                      )}
                    </div>
                    <div
                      className="newActionsWrapper"
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip title="Download" arrow>
                        <IconButton
                          onClick={() =>
                            handleDownload(
                              file?.document_name,
                              file?.portfolio_id
                            )
                          }
                          className="downloadFileButton"
                        >
                          <DownloadIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete" arrow>
                        <IconButton
                          color="error"
                          onClick={() => handleOpenDialog(file?.portfolio_id)}
                          className="removeFileButton"
                          disabled={
                            isUploading ||
                            file?.processing_status !== "Completed"
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </div>

                    <div
                      className="newFileSize"
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="selectAll"
                        className="restoreCheckbox"
                        disabled={
                          file?.processing_status === "In Progress" ||
                          file?.processing_status === "Completed"
                        }
                        onChange={() =>
                          handleCheckboxChange(file?.portfolio_id)
                        }
                      />
                    </div>
                  </li>
                ))}
            </ul>
          </div>

          {/* New Files List */}
          <div className="newFilesWrapper">
            <ul className="newFilesList">
              {files?.length > 0 &&
                files.map((file) => (
                  <li key={file?.name} className="newFileItem">
                    <div className="uploadStatus">
                      {uploadStatus[file?.name] === "success" ? (
                        <SuccessIcon color="success" />
                      ) : uploadStatus[file?.name] === "error" ? (
                        <ErrorIcon color="error" />
                      ) : (
                        <DriveFolderUploadRoundedIcon color="info" />
                      )}
                    </div>
                    <div
                      className="newFileName"
                      title={file?.name}
                      style={{ width: "500px" }}
                    >
                      {file?.name}
                    </div>
                    <div className="newFileDate">
                      {new Date().toISOString().split("T")[0]}
                    </div>
                    <div
                      className="newFileSize"
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      {formatFileSize(fileSizes[file?.name])}
                    </div>
                    <div
                      className="newFileSize"
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      <StatusCircle status="" value={"Inactive"} />
                    </div>
                    <div
                      className="newActionsWrapper"
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip title="Download" arrow>
                        <IconButton
                          className="downloadFileButton"
                          disabled={true}
                        >
                          <DownloadIcon
                            color="primary"
                            style={{
                              cursor: "not-allowed",
                              color: "#0000001f",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete" arrow>
                        <IconButton
                          onClick={() => handleRemove(file?.name)}
                          className="deleteFileButton"
                          disabled={isUploading}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div
                      className="newFileSize"
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      {"-"}
                    </div>
                  </li>
                ))}
            </ul>
          </div>

          {/* Upload Button */}
          {files.length > 0 && (
            <button
              onClick={handleUpload}
              className="uploadButton"
              style={{
                backgroundColor: isUploading
                  ? "rgb(197, 197, 214)"
                  : "rgb(41, 47, 98)",
                color: isUploading ? "rgba(0, 0, 0, 0.26)" : "#fff",
                cursor: isUploading ? "not-allowed" : "pointer",
                margin: "inherit",
              }}
              disabled={isUploading}
            >
              Upload Files
            </button>
          )}
        </div>
      </div>
      {files?.length > 0 && (
        <p
          style={{
            margin: 0,
            fontSize: 12,
            color: "#E65100",
          }}
        >
          {"Click on Upload Files button to submit document(s)."}
        </p>
      )}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Delete Document</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this document?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUplodedRemove} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
      {isLoading && (
        <Box
          className="loaderPage"
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 2000,
          }}
        >
          <RotateRightIcon
            color="inherit"
            fontSize="large"
            sx={{ animation: "spin 2s infinite ease-in-out" }}
          />
        </Box>
      )}
    </>
  );
}

export default UploadFiles;
