import React from "react";
import { Breadcrumbs, Typography, Link } from "@mui/material";
import {
  Home as HomeIcon,
  ChevronLeft as ChevronLeftIcon,
} from "@mui/icons-material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateBTEV, updateBTEVID } from "../redux/reducer/twoPagerSlice";

function CustomBreadcrumb({
  showBack = true,
  title,
  subTitle = "",
  params = "",
  companyName = "",
  view = "",
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleBack = () => {
    if (title === "Final Two Pager") {
      navigate(`/view-generate-2pager/${params}`, {
        state: { doc: companyName },
      });
      dispatch(updateBTEV(true));
      dispatch(updateBTEVID(params));
    } else {
      navigate("/");
    }
  };

  let IconComponent;

  if (title === "Dashboard") {
    IconComponent = HomeIcon;
  } else {
    IconComponent = InsertDriveFileIcon;
  }
  return (
    <div style={{ margin: "20px 28px 0" }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ display: "flex", gap: 1 }}>
        {/* Conditionally Render "Back" Breadcrumb */}
        {showBack && (
          <Link
            href="#"
            color="inherit"
            sx={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
            onClick={() => handleBack()}
          >
            <ChevronLeftIcon />
            <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
              Back
            </Typography>
          </Link>
        )}

        {/* Always Render "Dashboard" */}
        <Link
          href="#"
          sx={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            textDecoration: "none",
            color: "#61a3ff", // Custom color for highlight
          }}
        >
          <IconComponent sx={{ marginRight: 0.5 }} />
          <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
            {title}
          </Typography>
          {view === "2Pager" && (
            <Typography
              style={{
                color: "#19213D",
                fontSize: "10px",
                fontWeight: "light",
                display: "initial",
                padding: "0 5px",
                textTransform: "capitalize",
                fontWeight: 600,
              }}
            >
              Version : {subTitle}
            </Typography>
          )}
        </Link>
      </Breadcrumbs>
    </div>
  );
}

export default CustomBreadcrumb;
