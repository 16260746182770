import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  TableSortLabel,
  TablePagination,
  Tooltip,
  Typography,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchFailedGenerations, downloadGeneration } from "./service";

const FailedGenerations = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [generations, setGenerations] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [docType, setDocType] = useState("CIM");

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getFailedGenerations = async (val) => {
    try {
      setIsLoading(true);
      const response = await fetchFailedGenerations(val);

      if (response?.status === "success") {
        setGenerations(response?.data);
        toast.success(response?.message, {
          position: "top-right",
          autoClose: 5000,
        });
      } else if (response?.status === "error") {
        toast.error(response?.message, {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      const errorMessage = error?.response?.message || "Something went wrong!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const searchedGenerations = generations?.filter(
    (item) =>
      item?.company_name.toString().toLowerCase().includes(searchQuery) ||
      item?.document_name.toString().toLowerCase().includes(searchQuery) ||
      item?.version.toString().toLowerCase().includes(searchQuery) ||
      item?.modified_by_name.toString().toLowerCase().includes(searchQuery) ||
      item?.failure_stage.toString().toLowerCase().includes(searchQuery)
  );

  const paginatedGenerations = searchedGenerations?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
    setPage(0);
  };

  const handleDocumentType = (event) => {
    const val = event.target.value;
    setDocType(val);
    setSearchQuery("");
  };

  const formatDate = (timestamp) => {
    const formattedTimestamp = timestamp.replace("_", " ");

    const date = new Date(formattedTimestamp);
    if (isNaN(date)) {
      return "Invalid date";
    }

    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    return `${month}-${day}-${year}`;
  };

  const handleCopy = (textToCopy) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          toast.success("Text copied to clipboard!", {
            position: "top-right",
            autoClose: 3000,
          });
        })
        .catch((error) => {
          toast.error("Failed to copy text!", {
            position: "top-right",
            autoClose: 3000,
          });
        });
    } else {
      // Fallback for older browsers
      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand("copy");
        toast.success("Text copied to clipboard!", {
          position: "top-right",
          autoClose: 3000,
        });
      } catch (err) {
        toast.error("Failed to copy text!", {
          position: "top-right",
          autoClose: 3000,
        });
      }
      document.body.removeChild(textarea);
    }
  };

  const handleDownload = async (item) => {
    try {
      const response = await downloadGeneration(item?.available_blob_list);
      if (response) {
        let newFileName;

        if (item?.document_type === "CIM") {
          newFileName = `${item?.company_name}_Two Pager_${item?.version}_Container Logs.zip`;
        } else {
          newFileName = `${item?.company_name}_PortCo_Container Logs.zip`;
        }

        const blob = new Blob([response.data], { type: "application/zip" });
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${newFileName}`);
        document.body.appendChild(link);
        link.click();
        link.remove();

        toast.success("File downloaded successfully!", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (response) {
      toast.error(response?.status, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    getFailedGenerations(docType);
  }, [docType]);

  return (
    <>
      <Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "normal",
            marginBottom: 16,
          }}
        >
          <FormControl
            fullWidth
            margin="dense"
            className="compact-input"
            style={{ maxWidth: "130px", margin: "0" }}
          >
            <InputLabel>Select Document Type</InputLabel>
            <Select
              label="documentType"
              value={docType}
              onChange={handleDocumentType}
              fullWidth
            >
              <MenuItem value="CIM">CIM</MenuItem>
              <MenuItem value="PORTCO">PortCo</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            onChange={handleSearchChange}
            style={{ maxWidth: "250px", marginLeft: 20 }}
          />
        </Box>
        <Paper>
          <TableContainer style={{ maxHeight: "400px", overflowY: "auto" }}>
            <Table>
              <TableHead
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#fff",
                  zIndex: 1,
                }}
              >
                <TableRow>
                  <TableCell style={{ textAlign: "left" }}>
                    Company Name
                  </TableCell>
                  <TableCell style={{ textAlign: "left" }}>
                    Document Name
                  </TableCell>
                  <TableCell>Document Id</TableCell>
                  <TableCell>Version</TableCell>
                  <TableCell>Last Modified</TableCell>
                  <TableCell>Modified By</TableCell>
                  <TableCell>Failure Stage</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedGenerations?.length > 0 ? (
                  paginatedGenerations?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ textAlign: "left" }}>
                        {item?.company_name}
                      </TableCell>
                      <TableCell style={{ textAlign: "left" }}>
                        {item?.document_name}
                      </TableCell>
                      <TableCell onClick={() => handleCopy(item?.document_id)}>
                        <Tooltip title={item?.document_id}>
                          <span>{item?.document_id_display}....</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{item?.version}</TableCell>
                      <TableCell>
                        {formatDate(item?.timestamp_modified)}
                      </TableCell>
                      <TableCell>{item?.modified_by_name}</TableCell>
                      <TableCell>{item?.failure_stage}</TableCell>
                      <TableCell>
                        <Tooltip title="Download" arrow>
                          <IconButton
                            onClick={() => handleDownload(item)}
                            disabled={item?.log_available === false}
                          >
                            <DownloadIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <Typography style={{ padding: 20, display: "flex" }}>
                    {" "}
                    {"No data found."}
                  </Typography>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={searchedGenerations?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
      </Box>

      <ToastContainer />
      {isLoading && (
        <Box
          className="loaderPage"
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 2000,
          }}
        >
          <RotateRightIcon
            color="inherit"
            fontSize="large"
            sx={{ animation: "spin 2s infinite ease-in-out" }}
          />
        </Box>
      )}
    </>
  );
};

export default FailedGenerations;
