import React, { useEffect, useState, version } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Box,
  Divider,
  Grid,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate } from "react-router-dom";
import Loader from "../common/Loader";
import ExportToWordOrPdf from "./ExportToWordOrPdf";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { delete2Pager } from "./service";
import RotateRightIcon from "@mui/icons-material/RotateRight";

const Accordian = ({ accordianData, jsonData, access }) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setRows([]);
    setLoading(true); // Start loading
    // Simulate data loading
    const loadData = setTimeout(() => {
      setRows(accordianData);

      setLoading(false); // Data is loaded
    }, 2000); // Simulate a 2-second loading time

    return () => clearTimeout(loadData); // Cleanup timeout on unmount
  }, [accordianData]);

  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);

  const handleCheckboxChange = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, id];
    } else if (selectedIndex === 0) {
      newSelected = selected.slice(1);
    } else if (selectedIndex === selected.length - 1) {
      newSelected = selected.slice(0, -1);
    } else if (selectedIndex > 0) {
      newSelected = selected
        .slice(0, selectedIndex)
        .concat(selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const [exportDetails, setExportDetails] = useState([]);

  const formatDate = (timestamp) => {
    try {
      // Split the timestamp into date and time parts
      const [datePart, timePart] = timestamp.split("_");

      // Remove milliseconds from timePart
      const [time] = timePart.split(".");

      // Create a new Date object without milliseconds
      const date = new Date(`${datePart}T${time}Z`);
      // console.log(datePart,"datedatedatedate",time)

      // Check if the date is valid
      if (isNaN(date.getTime())) {
        throw new Error("Invalid Date");
      }

      // Options for formatting the date and time
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      };

      // Format the date and time
      const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
        date
      );

      return formattedDate;
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Invalid date";
    }
  };

  const handleView2PagerDoc = (two_pager_id, doc, version) => {
    navigate(`/view-generate-2pager/${two_pager_id}`, {
      state: { doc: doc, version: version },
    });
  };

  const handleCreateNewDoc = (two_pager_id, doc, version, status) => {
    navigate(`/create-new-doc/${two_pager_id}`, {
      state: { doc: doc, version: version, status: status },
    });
  };

  const getStatusColor = (value, bool) => {
    if (bool === true) {
      return "#fff";
    }
    if (value === "Completed") {
      return "#26B144";
    }
    if (value === "In Progress") {
      return "#DD9A19";
    }
    if (value === "Failed") {
      return "#FF4E28";
    }
    return "";
  };

  const handleCopy = (textToCopy) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          toast.success("Text copied to clipboard!", {
            position: "top-right",
            autoClose: 3000,
          });
        })
        .catch((error) => {
          toast.error("Failed to copy text!", {
            position: "top-right",
            autoClose: 3000,
          });
        });
    } else {
      // Fallback for older browsers
      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand("copy");
        toast.success("Text copied to clipboard!", {
          position: "top-right",
          autoClose: 3000,
        });
      } catch (err) {
        toast.error("Failed to copy text!", {
          position: "top-right",
          autoClose: 3000,
        });
      }
      document.body.removeChild(textarea);
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState("");
  const handleOpenDialog = (data) => {
    console.log(data);
    setItemToDelete(data);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setItemToDelete(null);
  };

  const handleDelete2Pager = async () => {
    try {
      setIsLoading(true);
      const response = await delete2Pager(itemToDelete?.two_pager_id, null);
      if (response?.status === "success") {
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setIsLoading(false);
      setOpenDialog(false);
      setItemToDelete(null);
    }
  };
  return (
    <>
      {loading ? (
        <Loader top={"50%"} />
      ) : (
        <>
          <TableContainer
            component={Paper}
            style={{
              backgroundColor: "transparent",
              boxShadow: "none",
              border: "none",
              borderRadius: 0,
            }}
          >
            {rows.length === 0 && !loading ? (
              <p
                style={{
                  marginTop: "50px",
                  color: "#ee6b6b",
                  textAlign: "center",
                }}
              >
                No data to display!
              </p>
            ) : (
              <Table>
                <TableHead style={{ backgroundColor: "#dae6f1" }}>
                  <TableRow>
                    <TableCell
                      style={{
                        color: "#292F62",
                        fontSize: "12px",
                        padding: "12px",
                      }}
                    >
                      Processing Status
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#292F62",
                        fontSize: "12px",
                        padding: "12px",
                      }}
                    >
                      Company Name
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#292F62",
                        fontSize: "12px",
                        padding: "12px",
                      }}
                    >
                      Document Name
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#292F62",
                        fontSize: "12px",
                        padding: "12px",
                      }}
                    >
                      Document ID
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#292F62",
                        fontSize: "12px",
                        padding: "12px",
                      }}
                    >
                      Version
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#292F62",
                        fontSize: "12px",
                        padding: "12px",
                      }}
                    >
                      Time (EST)
                    </TableCell>
                    {rows[0]?.generatedBy !== undefined && (
                      <TableCell
                        style={{
                          color: "#292F62",
                          fontSize: "12px",
                          padding: "12px",
                        }}
                      >
                        Generated By
                      </TableCell>
                    )}
                    <TableCell
                      style={{
                        color: "#292F62",
                        fontSize: "12px",
                        padding: "12px",
                      }}
                    >
                      Modified By
                    </TableCell>

                    <TableCell
                      style={{
                        color: "#292F62",
                        fontSize: "12px",
                        padding: "12px",
                      }}
                    >
                      Export
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#292F62",
                        fontSize: "12px",
                        padding: "12px",
                      }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={row.two_pager_id_display}
                      hover
                      role="checkbox"
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#F7F8FA" : "#ffffff",
                      }}
                    >
                      <TableCell
                        style={{
                          border: "none",
                          color: `${getStatusColor(row.status)}`,
                          fontSize: "12px",
                          padding: "12px",
                        }}
                      >
                        {row.status}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "none",
                          fontSize: "12px",
                          padding: "12px",
                        }}
                      >
                        {row.companyName}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "none",
                          fontSize: "12px",
                          padding: "12px",
                        }}
                      >
                        {row.docName}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "none",
                          fontSize: "12px",
                          padding: "12px",
                        }}
                        onClick={() => handleCopy(row.two_pager_id)}
                      >
                        <Tooltip title={row.two_pager_id}>
                          <span>{row.two_pager_id.substring(0, 8)}....</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "none",
                          fontSize: "12px",
                          padding: "12px",
                        }}
                      >
                        {row.version}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "none",
                          fontSize: "12px",
                          padding: "12px",
                        }}
                      >
                        {formatDate(row.timeModified)}
                      </TableCell>
                      {row?.generatedBy !== undefined ? (
                        <TableCell
                          style={{
                            border: "none",
                            fontSize: "12px",
                            padding: "12px",
                          }}
                        >
                          {row?.generatedBy}
                        </TableCell>
                      ) : (
                        ""
                      )}
                      <TableCell
                        style={{
                          border: "none",
                          fontSize: "12px",
                          padding: "12px",
                        }}
                      >
                        {row?.modifiedBy}
                      </TableCell>

                      <TableCell
                        style={{
                          border: "none",
                          fontSize: "12px",
                          padding: "12px",
                        }}
                      >
                        <ExportToWordOrPdf data={accordianData} record={row} />
                      </TableCell>

                      {row.status === "Completed" ? (
                        <TableCell
                          style={{
                            border: "none",
                            fontSize: "12px",
                            padding: "12px",
                          }}
                        >
                          <Tooltip title="View/Edit" arrow>
                            {" "}
                            <RemoveRedEyeIcon
                              style={{ fontSize: 19, cursor: "pointer" }}
                              onClick={() =>
                                handleView2PagerDoc(
                                  row.two_pager_id,
                                  row.companyName,
                                  row.version
                                )
                              }
                            />
                          </Tooltip>
                          <Tooltip title="Delete" arrow>
                            <DeleteIcon
                              style={{
                                fontSize: 22,
                                color: "#292f62",
                                opacity: access === "no" ? 0.5 : 1,
                                margin: "0 3px",
                                cursor: "pointer",
                                pointerEvents:
                                  access === "no" ? "none" : "auto",
                              }}
                              onClick={() => handleOpenDialog(row)}
                              disabled={access === "no"}
                            />
                          </Tooltip>
                        </TableCell>
                      ) : (
                        <TableCell style={{ border: "none" }}>
                          <Tooltip title="View/Edit" arrow>
                            {" "}
                            <RemoveRedEyeIcon
                              style={{ fontSize: 19, cursor: "pointer" }}
                              onClick={() =>
                                handleCreateNewDoc(
                                  row.two_pager_id,
                                  row.companyName,
                                  row.version,
                                  row.status
                                )
                              }
                            />
                          </Tooltip>
                          <Tooltip title="Delete" arrow>
                            <DeleteIcon
                              style={{
                                fontSize: 22,
                                color: "#292f62",
                                opacity:
                                  access === "no" ||
                                  row.status === "Deleted" ||
                                  row?.status === "In Progress"
                                    ? 0.5
                                    : 1,
                                margin: "0 3px",
                                cursor: "pointer",
                                pointerEvents:
                                  access === "no" ||
                                  row.status === "Deleted" ||
                                  row?.status === "In Progress"
                                    ? "none"
                                    : "auto",
                              }}
                              onClick={() => handleOpenDialog(row)}
                              disabled={
                                access === "no" ||
                                row.status === "Deleted" ||
                                row?.status === "In Progress"
                              }
                            />
                          </Tooltip>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>

          <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md">
            {/* <DialogTitle>Delete</DialogTitle> */}
            <DialogContent>
              <Box sx={{ mb: 2 }}>
                <Typography variant="h6" gutterBottom>
                  {`Are you sure you want to delete this record for ${
                    itemToDelete?.companyName + " " + itemToDelete?.version
                  }?`}
                </Typography>

                <Divider sx={{ mb: 3 }} />

                <Grid
                  container
                  spacing={2}
                  sx={{ fontSize: "14px", lineHeight: 1.6 }}
                >
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      sx={{ color: "#292F62", fontWeight: "bold" }}
                    >
                      Company Name:
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2">
                      {itemToDelete?.companyName}
                    </Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      sx={{ color: "#292F62", fontWeight: "bold" }}
                    >
                      CIM Document Name:
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2">
                      {itemToDelete?.docName}
                    </Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      sx={{ color: "#292F62", fontWeight: "bold" }}
                    >
                      Two Pager ID:
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2">
                      {itemToDelete?.two_pager_id}
                    </Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      sx={{ color: "#292F62", fontWeight: "bold" }}
                    >
                      Version:
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2">
                      {itemToDelete?.version}
                    </Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      sx={{ color: "#292F62", fontWeight: "bold" }}
                    >
                      Modified By:
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2">
                      {itemToDelete?.modifiedBy}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDelete2Pager} color="secondary">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          {isLoading && (
            <Box
              className="loaderPage"
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 2000,
              }}
            >
              <RotateRightIcon
                color="inherit"
                fontSize="large"
                sx={{ animation: "spin 2s infinite ease-in-out" }}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default Accordian;
