import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./style.css";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { Typography } from "@mui/material";
import rightSwiftIcon from "../../Assets/Icons/right-swift.svg";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import {
  updateFilterData,
  // updateDashboardSearch,
  updateFilterResetState,
} from "../redux/reducer/dashboardSlice";
import RefreshIcon from "@mui/icons-material/Refresh";
import dayjs from "dayjs";

const StyledSelect = styled(Select)(({ theme }) => ({
  "& select": {
    borderColor: "#DFDFDF", // Set the border color to light grey
    borderWidth: "1px", // Set the border width
  },
}));

const ColorButton = styled(Button)(({ theme }) => ({
  height: 38,
  marginTop: 10,
  borderRadius: 0,
  // border: "1px solid #61a3ff",
  background: "transparent",
  color: "#2a2f5f",
  boxShadow: "none",
  // marginRight: 10,
  textTransform: "capitalize",
  padding: 0,
  minWidth: 0,
  "& svg": {
    fontSize: 24,
  },
  "&:hover": {
    // backgroundColor: "#61a3ff",
    // borderColor: "#61a3ff",
    // boxShadow: "none",
    color: "#61a3ff",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
}));
const customTheme = createTheme({
  components: {
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: "13px",
          color: "#292F62",
        },
      },
    },
  },
});

const Filter = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = React.useState("");
  const [searchInput, setSearchInput] = React.useState("");
  const [selectedStartDate, setSelectedStartDate] = React.useState(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState(null);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);

    if (event.target.value === "All") {
      handleApplyFilter(null, searchInput, selectedStartDate, selectedEndDate);
    } else {
      handleApplyFilter(
        event.target.value,
        searchInput,
        selectedStartDate,
        selectedEndDate
      );
    }
  };

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
    dispatch(
      updateFilterData({
        status: status,
        searchInput: event.target.value,
        selectedStartDate: selectedStartDate,
        selectedEndDate: selectedEndDate,
      })
    );
  };

  // const handleSearch = () => {
  //   dispatch(updateDashboardSearch({ searchInput }));
  // };
  // const handleSearchKeyPress = (event) => {
  //   if (event.key === "Enter") {
  //     event.preventDefault();
  //     handleSearch();
  //   }
  // };

  const handleStartDateChange = (newValue) => {
    setSelectedStartDate(newValue);
    handleApplyFilter(status, searchInput, newValue, selectedEndDate);
  };

  const handleEndDateChange = (newValue) => {
    setSelectedEndDate(newValue);
    handleApplyFilter(status, searchInput, selectedStartDate, newValue);
  };

  const handleApplyFilter = (statusData, searchData, startDate, endDate) => {
    dispatch(
      updateFilterData({
        status: statusData,
        searchInput: searchData,
        selectedStartDate: startDate,
        selectedEndDate: endDate,
      })
    );
  };

  const handleResetFilter = () => {
    setSearchInput("");
    setSelectedStartDate(null);
    setStatus("");
    setSelectedEndDate(null);
    dispatch(updateFilterResetState({ isReset: true }));
  };

  return (
    <ThemeProvider theme={customTheme}>
      <section className="filter-container">
        <FormControl
          sx={{
            margin: "10px 15px 0 0",
            minWidth: 120,
            "& .MuiOutlinedInput-notchedOutline": { borderColor: "#DFDFDF" },
          }}
          size="small"
        >
          <InputLabel id="demo-select-small-label" style={{ color: "#878790" }}>
            Status
          </InputLabel>
          <StyledSelect
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={status}
            label="Status"
            style={{ height: 38, borderRadius: 0 }}
            onChange={handleStatusChange}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value={"completed"}>Completed</MenuItem>
            <MenuItem value={"in progress"}>In Progress</MenuItem>
            <MenuItem value={"failed"}>Failed</MenuItem>
            <MenuItem value={"deleted"}>Deleted</MenuItem>
          </StyledSelect>
        </FormControl>

        <Paper
          component="form"
          sx={{
            height: 36,
            margin: "10px 0 0 0",
            boxShadow: "none",
            border: "1px solid #DFDFDF",
            borderRadius: 0,
          }}
        >
          <InputBase
            value={searchInput}
            onChange={handleSearchChange}
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search"
            inputProps={{
              style: { fontSize: 13, padding:"9px 0" },
            }}
            // onKeyDown={handleSearchKeyPress}
          />

          {/* <IconButton
            color="primary"
            sx={{ p: "6px", backgroundColor: "primary" }}
            onClick={() => handleSearch()}
          >
            <SearchIcon />
          </IconButton> */}
        </Paper>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              label="Start Date"
              value={selectedStartDate}
              maxDate={dayjs()}
              onChange={handleStartDateChange}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#DFDFDF",
                },
                "& .MuiInputBase-root.MuiOutlinedInput-root": {
                  fontSize: "13px",
                  height: "38px",
                  width: "160px",
                  overflow: "hidden",
                  borderRadius: 0,
                },
                "& .MuiInputLabel-root": {
                  color: "#878790",
                },
              }}
            />
          </DemoContainer>
        </LocalizationProvider>

        <img src={rightSwiftIcon} alt="Right Swift Icon" />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              label="End Date"
              value={selectedEndDate}
              onChange={handleEndDateChange}
              minDate={selectedStartDate}
              maxDate={dayjs()}
              sx={{
                "& .MuiFormControl-root": {
                  minWidth: "auto",
                  marginRight: 10,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#DFDFDF",
                },
                "& .MuiInputBase-root.MuiOutlinedInput-root": {
                  fontSize: "13px",
                  height: "38px",
                  width: "160px",
                  overflow: "hidden",
                  borderRadius: 0,
                },
                "& .MuiInputLabel-root": {
                  color: "#878790",
                },
              }}
            />
          </DemoContainer>
        </LocalizationProvider>

        {/* <ColorButton
          variant="contained"
          onClick={() => handleApplyFilter()}
          startIcon={<FilterAltOutlinedIcon />}
        >
          <Typography className="create-new-doc-btn-text"> Filter</Typography>
        </ColorButton> */}
        <ColorButton
          variant="contained"
          onClick={() => handleResetFilter()}
          startIcon={<RefreshIcon />}
        >
          {/* <Typography className="create-new-doc-btn-text">Reset</Typography> */}
        </ColorButton>
      </section>
    </ThemeProvider>
  );
};

export default Filter;
