import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Grid,
  Button,
  Autocomplete,
  TextField,
  Typography,
} from "@mui/material";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import { toast, ToastContainer } from "react-toastify";
import { fetchFeedbackData, fetchUserList, downloadFeedback } from "./service";
import FeedbackPieChart from "./FeedbackPieChart";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RefreshIcon from "@mui/icons-material/Refresh";

const Feedback = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [feedback, setFeedback] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const getUsers = async () => {
    try {
      setIsLoading(true);
      const response = await fetchUserList();
      if (response?.status === "success") {
        setUsers(response?.data);
        // toast.success(response?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        // });
      } else if (response?.status === "error") {
        toast.error(response?.message, {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      const errorMessage = error?.response?.message || "Something went wrong!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getFeedbackData = async (user, start, end) => {
    try {
      setIsLoading(true);
      const response = await fetchFeedbackData(user, start, end);

      if (response?.status === "success") {
        setFeedback(response);
        toast.success(response?.message, {
          position: "top-right",
          autoClose: 5000,
        });
      } else if (response?.status === "error") {
        toast.error(response?.message, {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      const errorMessage = error?.response?.message || "Something went wrong!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUser = (event, newValue) => {
    setSelectedUser(newValue);
    getFeedbackData(
      newValue,
      startDate !== null
        ? new Date(startDate).toLocaleDateString("en-CA")
        : null,
      endDate !== null ? new Date(endDate).toLocaleDateString("en-CA") : null
    );
  };

  const handleResetFilter = () => {
    setSelectedUser(null);
    handleDefaultFilters();
  };

  const handleDownloadFeedback = async () => {
    const formatSDate =
      startDate !== null
        ? new Date(startDate).toLocaleDateString("en-CA")
        : null;
    const formatEDate =
      endDate !== null ? new Date(endDate).toLocaleDateString("en-CA") : null;
    try {
      const response = await downloadFeedback(
        selectedUser,
        formatSDate,
        formatEDate
      );
      if (response) {
        let newFileName = `Rainmaker User Feedback_${formatDate(
          new Date()
        )}.xlsx`;

        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", newFileName);
        document.body.appendChild(link);
        link.click();
        link.remove();

        toast.success("File downloaded successfully!", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (response) {
      toast.error(response?.status, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  function formatDate(inputDate) {
    // Create a new Date object from the input date string
    let date = new Date(inputDate);

    // Extract components of the date
    let month = date.getMonth() + 1; // getMonth() returns zero-based month
    let day = date.getDate();
    let year = date.getFullYear();

    // Ensure month and day are formatted to have leading zeros if necessary
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    // Concatenate the formatted date in MM.DD.YYYY format
    return month + "." + day + "." + year;
  }

  const handleDefaultFilters = () => {
    const today = new Date();
    const sixMonthsAgo = new Date(today);
    sixMonthsAgo.setMonth(today.getMonth() - 6);

    setStartDate(sixMonthsAgo);
    setEndDate(today);
    getFeedbackData(
      null,
      sixMonthsAgo.toLocaleDateString("en-CA"),
      today.toLocaleDateString("en-CA")
    );
  };

  useEffect(() => {
    getUsers();
    handleDefaultFilters();
  }, []);

  return (
    <>
      <Box
        style={{
          display: "flex",
          justifyContent: "normal",
          marginBottom: 16,
        }}
      >
        <Autocomplete
          disablePortal
          options={users}
          sx={{ width: 300 }}
          value={selectedUser}
          onChange={handleUser}
          className="compact-input compact-select"
          renderInput={(params) => (
            <TextField {...params} label="Select User" />
          )}
        />
        {/* <FormControl
          fullWidth
          margin="dense"
          className="compact-input compact-select"
          style={{ maxWidth: "190px", margin: "0" }}
        >
          <InputLabel>Select User</InputLabel>
          <Select
            label="Users"
            value={selectedUser}
            onChange={handleUser}
            fullWidth
          >
            {users?.map((user) => (
              <MenuItem key={user} value={user}>
                {user}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
        <div className="datepicker-container-horizontal">
          <div className="input-wrapper">
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
                getFeedbackData(
                  selectedUser,
                  new Date(date).toLocaleDateString("en-CA"),
                  endDate !== null
                    ? new Date(endDate).toLocaleDateString("en-CA")
                    : null
                );
              }}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="yyyy-MM-dd"
              className="datepicker-input"
            />
            <label
              className={startDate ? "floating-label active" : "floating-label"}
            >
              Start Date
            </label>
          </div>

          <div className="input-wrapper">
            <DatePicker
              selected={endDate}
              onChange={(date) => {
                setEndDate(date);
                getFeedbackData(
                  selectedUser,
                  startDate !== null
                    ? new Date(startDate).toLocaleDateString("en-CA")
                    : null,
                  new Date(date).toLocaleDateString("en-CA")
                );
              }}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              dateFormat="yyyy-MM-dd"
              className="datepicker-input"
            />
            <label
              className={endDate ? "floating-label active" : "floating-label"}
            >
              End Date
            </label>
          </div>
        </div>
        <RefreshIcon
          style={{
            display: "block",
            margin: "7px 0 7px 40px",
            cursor: "pointer",
            color: "#2a2f5f",
          }}
          onClick={() => handleResetFilter()}
        />
        <div style={{ marginLeft: "auto" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleDownloadFeedback()}
            className="addPortfolio"
            disabled={feedback?.pie_chart_data?.length === 0}
          >
            Export Feedback Report
          </Button>
        </div>
      </Box>
      <Box
        style={{
          display: "block",
          margin: "30px 0",
          padding: "20px 0",
        }}
      >
        <Paper style={{ padding: "0 20px" }}>
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              md={5}
              style={{ borderRight: "2px solid #dadada", paddingTop: 0 }}
            >
              <h4 style={{ fontWeight: 400 }}>Rainmaker User Feedback </h4>
              {feedback?.pie_chart_data?.length > 0 ? (
                <>
                  <FeedbackPieChart dataset={feedback?.pie_chart_data} />
                  <Typography
                    variant="body2"
                    component="p"
                    style={{
                      textAlign: "center",
                      padding: "40px 40px 40px 20px",
                    }}
                  >
                    This chart illustrates Rainmaker user feedback for the
                    selected time period, highlighting the distribution of
                    positive (Thumbs Up) and negative (Thumbs Down) responses.
                  </Typography>
                </>
              ) : (
                <h6 style={{ fontWeight: 400 }}>No data found!</h6>
              )}
            </Grid>

            <Grid item xs={12} md={7}>
              <Grid container spacing={4}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ borderBottom: "2px solid #dadada", paddingTop: 0 }}
                >
                  <h4 style={{ fontWeight: 400 }}>Top 5 Liked Bullets </h4>
                  <TableContainer
                    component={Paper}
                    style={{ marginBottom: 16 }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ textAlign: "left" }}>
                            Section{" "}
                          </TableCell>
                          <TableCell style={{ textAlign: "left" }}>
                            Sub Section{" "}
                          </TableCell>
                          <TableCell style={{ textAlign: "left" }}>
                            Bullet
                          </TableCell>
                          <TableCell style={{ textAlign: "left" }}>
                            Sub Bullet
                          </TableCell>
                          <TableCell>Count</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {feedback?.top_positive_bullets
                          ?.slice(0, 5)
                          .map((item, index) => (
                            <TableRow key={index}>
                              <TableCell style={{ textAlign: "left" }}>
                                {item.section}
                              </TableCell>
                              <TableCell style={{ textAlign: "left" }}>
                                {item.sub_section}
                              </TableCell>
                              <TableCell style={{ textAlign: "left" }}>
                                {item.bullet}
                              </TableCell>
                              <TableCell style={{ textAlign: "left" }}>
                                {item.sub_bullet}
                              </TableCell>
                              <TableCell>{item.count}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} md={12} style={{ paddingTop: 0 }}>
                  <h4 style={{ fontWeight: 400 }}>Top 5 Disliked Bullets </h4>
                  <TableContainer
                    component={Paper}
                    style={{ marginBottom: 16 }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ textAlign: "left" }}>
                            Section{" "}
                          </TableCell>
                          <TableCell style={{ textAlign: "left" }}>
                            Sub Section{" "}
                          </TableCell>
                          <TableCell style={{ textAlign: "left" }}>
                            Bullet
                          </TableCell>
                          <TableCell style={{ textAlign: "left" }}>
                            Sub Bullet
                          </TableCell>
                          <TableCell>Count</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {feedback?.top_negative_bullets
                          ?.slice(0, 5)
                          .map((item, index) => (
                            <TableRow key={index}>
                              <TableCell style={{ textAlign: "left" }}>
                                {item.section}
                              </TableCell>
                              <TableCell style={{ textAlign: "left" }}>
                                {item.sub_section}
                              </TableCell>
                              <TableCell style={{ textAlign: "left" }}>
                                {item.bullet}
                              </TableCell>
                              <TableCell style={{ textAlign: "left" }}>
                                {item.sub_bullet}
                              </TableCell>
                              <TableCell>{item.count}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <ToastContainer />
      {isLoading && (
        <Box
          className="loaderPage"
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 2000,
          }}
        >
          <RotateRightIcon
            color="inherit"
            fontSize="large"
            sx={{ animation: "spin 2s infinite ease-in-out" }}
          />
        </Box>
      )}
    </>
  );
};

export default Feedback;
