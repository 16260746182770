import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Box, Paper, Typography, Button, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import veritasImage from "../../Assets/Icons/Vector.svg";
import veritasCapitalLogo from "../../Assets/Icons/veritas-capital-logo.svg";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { MSAL_CONFIG } from "../../azure/authConfig";
import "./Header.css";
import { getVersion } from "./service"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import report from '../../Assets/Icons/report.png'

export default function Header() {
  const navigate = useNavigate();
  const { accounts, instance } = useMsal();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [name, setUserName] = useState("")
  const isAdmin = localStorage.getItem("admin")


  function extractUsernameFromEmail(email) {
    if (!email) return '';

    const username = email.split('@')[0];

    // Optional: Convert the username to proper case (e.g., "john.doe" to "John Doe")
    const formattedUsername = username.split('.').map(word =>
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ');

    return formattedUsername;
  }



  useEffect(() => {
    const response = JSON.parse(localStorage.getItem("userLoginInfo"))
    const userName = extractUsernameFromEmail(response.userEmail)
    setUserName(userName)
    const fetchVersion = async () => {
      try {
        const response = await getVersion();
        localStorage.setItem("releaseVersion", JSON.stringify(response?.data?.release_version));

      } catch (error) {
        console.log("error:", error);
      }
    };

    fetchVersion();
  }, [])

  const handleDashboard = () => {
    navigate("/");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  const signOutClickHandler = () => {
    const logoutRequest = {
      account: instance.getAccountByHomeId(accounts[0].homeAccountId),
      mainWindowRedirectUri: MSAL_CONFIG.auth.redirectUri,
      postLogoutRedirectUri: MSAL_CONFIG.auth.redirectUri,
    };
    instance.logoutRedirect(logoutRequest);
  };

  const redirectAdmin = () => {
    window.open(`${window.location.origin}/admin`, '_blank')
  }
  const handleCreateNewDoc = () => {
    navigate("/create-new-doc", {
      state: "NewGeneration",
    });
  };
  
  return (
    <>
      <header>
      </header>
      <Paper className="topBar">
        <Grid style={{ paddingTop: "11px", cursor: "pointer" }} onClick={handleDashboard}>
          <img src={veritasCapitalLogo} alt="Veritas Capital Logo" />
          <img src={veritasImage} style={{ width: '95px', height: '28px', left: '7.5px' }} alt="Veritas" />
        </Grid>

        <Grid sx={{alignContent: "space-evenly", cursor : "pointer"}}>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: '#292f62'
            }}

            onClick={() => handleCreateNewDoc()}
          >
            <img src={report} style={{height: "25px", paddingRight: "5px"}}/>
            {/* <OpenInNewIcon sx={{ color: '#61a3ff', marginRight: 1 }} /> */}
            Generate New Two-Pager
          </Typography>
        </Grid>

        <Grid>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton
              size="small"
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              style={{ gap: '5px', fontSize: 12, color: '#373B5C', border: '1px solid #E8EFF7', borderRadius: 0 }}
            >
              <div style={{ background: '#F4F7FC', borderRadius: '20px' }}>
                <PersonOutlineOutlinedIcon />
              </div>
              <div>
                <div style={{ color: '#373B5C', fontSize: '14px', fontWeight: '500' }}>{name}</div>
              </div>
              <KeyboardArrowDownOutlinedIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {isAdmin === "yes" && <MenuItem onClick={redirectAdmin}>Admin Panel</MenuItem>}
              <MenuItem onClick={signOutClickHandler}>Logout</MenuItem>
            </Menu>
          </Box>
        </Grid>
      </Paper>
    </>
  );
}
