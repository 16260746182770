import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { environment } from "../../environment";
import {
  Delete as DeleteIcon,
  CheckCircle as SuccessIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";
import { IconButton, Box, Typography, Tooltip } from "@mui/material";
import "./index.css";
import { deleteUplodedFiles } from "./service";
import { downloadPortfolio } from "../AdminPanel/service";
import DownloadIcon from "@mui/icons-material/Download";
import DriveFolderUploadRoundedIcon from "@mui/icons-material/DriveFolderUploadRounded";

function formatFileSize(sizeInBytes) {
  const MB = 1024 * 1024;
  let sizeInMB = sizeInBytes / MB;
  return `${sizeInMB.toFixed(1)} MB`;
}

function ExpertFileUpload(props) {
  const [expertFiles, setExpertFiles] = useState([]); // Holds the list of all selected files
  const [progress, setProgress] = useState({}); // Keep track of upload progress for each file
  const [uploadStatus, setUploadStatus] = useState({}); // Track success/failure per file
  const [expertFileSizes, setExpertFileSizes] = useState({}); // Track file sizes
  const [uploadedSizes, setUploadedSizes] = useState({}); // Track uploaded sizes
  const [alreadyUploaded, setAlreadyUploaded] = useState([]); // Track already uploaded files
  const [existingFiles, setExistingFiles] = useState(
    props?.expertUploadedFiles
  ); // Track already uploaded files
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const fileExpertInputRef = useRef(null);
  const isAdmin = localStorage.getItem("admin");
  const buildUrl = (path) => {
    return environment.baseUrl + path;
  };
  const getUserDetails = () => {
    const response = JSON.parse(localStorage?.getItem("userLoginInfo"));
    const data = {
      email_id: response?.userEmail,
      access_token: response?.access_token,
    };

    const options = {
      headers: data,
    };
    return options?.headers;
  };

  const handleExpertFileChange = (e) => {
    console.log("hi");

    const newFiles = e.target.files;
    let tempSizes = {};
    let tempFiles = [...expertFiles];

    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    // Loop through selected files and append them to the existing list
    [...newFiles].forEach((file) => {
      if (
        allowedTypes.includes(file?.type) &&
        !tempFiles.find((f) => f?.name === file?.name)
      ) {
        tempFiles.push(file);
        tempSizes[file?.name] = file?.size; // Store size in bytes
      } else if (!allowedTypes.includes(file?.type)) {
        toast.warn("Invalid file type. Please upload a PDF or Word document.");
      }
    });

    // Update state with new file list and sizes
    setExpertFiles(tempFiles);
    setExpertFileSizes((prevSizes) => ({ ...prevSizes, ...tempSizes }));

    if (fileExpertInputRef.current) {
      fileExpertInputRef.current.value = null;
    }
  };

  // Handle the file upload
  const handleExpertUpload = async () => {
    const tempProgress = { ...progress };
    const tempUploadedSizes = { ...uploadedSizes };

    const filesToUpload = expertFiles.filter(
      (file) => !alreadyUploaded.includes(file?.name)
    );
    const formData = new FormData();

    // Append all files to the FormData
    filesToUpload.forEach((file) => {
      formData.append("additional_files", file);
    });

    const config = {
      onUploadProgress: (progressEvent) => {
        const total = progressEvent.total;
        const loaded = progressEvent.loaded;
        const percentCompleted = Math.round((loaded * 100) / total);

        // Update progress for the combined upload
        setProgress({ combined: percentCompleted });

        // Update uploaded sizes for all files being uploaded
        tempUploadedSizes.total = loaded;
        setUploadedSizes(tempUploadedSizes);
      },
    };

    try {
      setIsLoading(true);
      const userHeaders = getUserDetails();
      const response = await axios.post(
        buildUrl(
          `cim_api/cim/upload_multiple?company_name=${props?.companyName}
          &deal_cloud_id=${props?.dealCloudId}&document_name=${
            props?.documentName
          }&document_type=${encodeURIComponent("Expert Call Note")}`
        ),
        formData,
        {
          headers: {
            ...userHeaders, // Spread the user headers here
            "Content-Type": "multipart/form-data",
          },
        }
      );
      filesToUpload.forEach((file) => {
        setUploadStatus((prev) => ({ ...prev, [file?.name]: "success" }));
        setAlreadyUploaded((prev) => [...prev, file?.name]); // Mark as uploaded
      });
      props?.getExpertReportId(response?.data?.data?.report_id);
      if (response?.data?.status !== "success") {
        throw new Error("Network response was not ok");
      }
      if (response?.data?.status === "success") {
        setIsUploading(true);
      }
      toast.success("File(s) uploaded successfully!");
    } catch (error) {
      filesToUpload.forEach((file) => {
        setUploadStatus((prev) => ({ ...prev, [file?.name]: "error" }));
      });
      console.error("Error uploading files:", error);
      toast.error(`Upload failed: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle the remove file request
  const handleRemove = (fileName) => {
    setExpertFiles((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileName)
    );

    setUploadStatus((prevStatus) => {
      const newStatus = { ...prevStatus };
      delete newStatus[fileName];
      return newStatus;
    });

    setProgress((prevProgress) => {
      const newProgress = { ...prevProgress };
      delete newProgress[fileName];
      return newProgress;
    });

    setExpertFileSizes((prevSizes) => {
      const newSizes = { ...prevSizes };
      delete newSizes[fileName];
      return newSizes;
    });

    setUploadedSizes((prevUploaded) => {
      const newUploaded = { ...prevUploaded };
      delete newUploaded[fileName];
      return newUploaded;
    });

    setAlreadyUploaded((prevUploaded) =>
      prevUploaded.filter((file) => file !== fileName)
    );

    // Clear the file input only if the deleted file was the last one
    if (fileExpertInputRef.current) {
      fileExpertInputRef.current.value = null;
    }
  };
  const handleUplodedRemove = async (file) => {
    try {
      const response = await deleteUplodedFiles(
        {
          expert_call_note_data: existingFiles,
          expert_call_note_id: file?.expert_call_note_id,
        },
        "False"
      );
      if (response) {
        setExistingFiles(response?.data?.report_data);
        props?.getExpertOldReportId(response?.data?.report_data);
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 5000, // Close the notification after 5 seconds
        });
      }
      // Clear the file input only if the deleted file was the last one
      if (fileExpertInputRef.current) {
        fileExpertInputRef.current.value = null;
      }
    } catch (response) {
      // toast.error(response?.status, {
      //   position: 'top-right',
      //   autoClose: 5000,
      // })
    }
  };

  const handleDownload = async (filename, portfolioId) => {
    try {
      setIsLoading(true);
      const newFileName = filename.replace(/\.[^/.]+$/, `.${"pdf"}`);
      const response = await downloadPortfolio(portfolioId);
      if (response) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${newFileName}`);
        document.body.appendChild(link);
        link.click();
        link.remove();

        toast.success("File downloaded successfully!", {
          position: "top-right",
          autoClose: 5000, // Close the notification after 5 seconds
        });
      }
    } catch (response) {
      toast.error(response?.status, {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setExistingFiles(props?.expertUploadedFiles);
  }, []);

  return (
    <>
      <div
        style={{
          border: "1px solid #ddd",
          padding: 10,
          // margin: "4px auto",
          borderRadius: 4,
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ mb: 1, fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}
        >
          {"Expert Call Note(s)"}
        </Typography>
        <div className="customFileInput">
          <input
            type="file"
            id="expert-files-upload"
            multiple
            onChange={(e) => handleExpertFileChange(e)}
            className="fileInput"
            disabled={!props?.clickable || isUploading}
            accept=".pdf,.doc,.docx"
            ref={fileExpertInputRef}
          />
          <label
            htmlFor="expert-files-upload"
            className="fileInputLabel"
            style={{
              cursor:
                !props?.clickable || isUploading ? "not-allowed" : "pointer",
              backgroundColor:
                !props?.clickable || isUploading
                  ? "rgb(197, 197, 214)"
                  : "#8f8f8f",
              border: 0,
              color:
                !props?.clickable || isUploading
                  ? "rgba(0, 0, 0, 0.26)"
                  : "rgb(255, 255, 255)",
            }}
          >
            Select File(s)
          </label>
        </div>
        {existingFiles?.length > 0 || expertFiles?.length > 0 ? (
          <>
            <div
              className="newFilesWrapper"
             
            >
              <div className="newFilesHeader"  style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 15,
                gap: 10,
                borderBottom: "1px solid #ddd",
                paddingBottom: 10,
              }}>
                <div className="headerItem">Status</div>
                <div
                  className="headerItem"
                  style={{
                    flexGrow: 1,
                    whiteSpace: "normal",
                    overflow: "hidden",
                    wordWrap: "break-word",
                  }}
                >
                  Document Name
                </div>

                <div
                  className="headerItem"
                  style={{
                    textAlign: "center",
                  }}
                >
                  Size
                </div>

                <div
                  className="headerItem"
                  style={{
                    textAlign: "center",
                  }}
                >
                  Actions
                </div>
              </div>
            </div>
            <ul className="fileList">
              {existingFiles?.length > 0 &&
                [...existingFiles]?.map((file, index) => (
                  <li key={index} className="fileItem">
                    <div className="statusMark">
                      <SuccessIcon color="success" />
                    </div>
                    <div
                      className="fileName"
                      title={file?.expert_call_note_doc_name}
                    >
                      {file?.expert_call_note_doc_name}
                    </div>
                    <div className="uploadedSize">{file?.size}</div>
                    <div
                      className="deleteSection"
                      style={{
                        cursor: isUploading ? "not-allowed" : "pointer",
                      }}
                    >
                      {" "}
                      <Tooltip title="Delete" arrow>
                        <IconButton
                          onClick={() => handleUplodedRemove(file)}
                          className="deleteButton"
                          disabled={isUploading}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </Tooltip>
                      {isAdmin === "yes" && (
                        <Tooltip title="Download" arrow>
                          <IconButton
                            style={{
                              color: "#292F62",
                            }}
                            className="downloadFileButton"
                            onClick={() =>
                              handleDownload(
                                file?.expert_call_note_doc_name,
                                file?.expert_call_note_id
                              )
                            }
                          >
                            <DownloadIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </li>
                ))}
            </ul>
            <ul className="fileList">
              {expertFiles?.length > 0 &&
                [...expertFiles]?.map((file) => (
                  <li key={file?.name} className="fileItem">
                    <div className="statusMark">
                      {uploadStatus[file?.name] === "success" ? (
                        <SuccessIcon color="success" />
                      ) : uploadStatus[file?.name] === "error" ? (
                        <ErrorIcon color="error" />
                      ) : (
                        <DriveFolderUploadRoundedIcon color="info" />
                      )}
                    </div>
                    <div className="fileName" title={file?.name}>
                      {file?.name}
                    </div>
                    <div className="uploadedSize">
                      {formatFileSize(expertFileSizes[file?.name])}
                    </div>

                    <div
                      className="deleteSection"
                      style={{
                        cursor: isUploading ? "not-allowed" : "pointer",
                      }}
                    >
                      <Tooltip title="Delete" arrow>
                        <IconButton
                          onClick={() => handleRemove(file?.name)}
                          className="deleteButton"
                          disabled={isUploading}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </li>
                ))}
            </ul>
            {expertFiles.length > 0 && (
              <button
                onClick={handleExpertUpload}
                className="uploadButton"
                style={{
                  backgroundColor: isUploading
                    ? "rgb(197, 197, 214)"
                    : "rgb(41, 47, 98)",
                  color: isUploading ? "rgba(0, 0, 0, 0.26)" : "#fff",
                  cursor: isUploading ? "not-allowed" : "pointer",
                }}
                disabled={isUploading}
              >
                Upload Files
              </button>
            )}
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              float: "left",
              padding: "25px 0 15px",
            }}
          >
            <Typography>{"No file(s) selected!"}</Typography>
          </Box>
        )}
      </div>
      {expertFiles?.length > 0 && !isUploading && (
        <p
          style={{
            margin: 0,
            fontSize: 12,
            color: "#E65100",
          }}
        >
          {"Click on Upload Files button to submit expert call note(s)."}
        </p>
      )}
      {isLoading && (
        <Box
          className="loaderPage"
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 2000,
          }}
        >
          <RotateRightIcon
            color="inherit"
            fontSize="large"
            sx={{ animation: "spin 2s infinite ease-in-out" }}
          />
        </Box>
      )}
    </>
  );
}

export default ExpertFileUpload;
