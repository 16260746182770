import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  TableSortLabel,
  TablePagination,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast, ToastContainer } from "react-toastify";
import {
  fetchPortfolioData,
  deletePortfolio,
  submitPortfolio,
} from "./service";
import DifferenceIcon from "@mui/icons-material/Difference";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import { useNavigate } from "react-router-dom";

const ViewPortfolioList = ({}) => {
  const navigate = useNavigate();
  const [portfolios, setPortfolios] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [portfolioToDelete, setPortfolioToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("created_date");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [isProcessing, setIsProcessing] = useState(true);

  const handleOpenDialog = (id) => {
    setPortfolioToDelete(id);
    setOpenDialog(true);
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const response = await deletePortfolio(portfolioToDelete, null);
      if (response?.status === "success") {
        window.location.reload();
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setIsLoading(false);
      setOpenDialog(false);
      setPortfolioToDelete(null);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setPortfolioToDelete(null);
  };

  const getPortfolioData = async (intervalId) => {
    try {
      setIsLoading(true);
      const response = await fetchPortfolioData(null);
      if (response?.status === "success") {
        setPortfolios(response?.data);
        if (response?.processing === false) {
          setIsProcessing(false);
          clearInterval(intervalId); // Stop polling
        }
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
    setPage(0);
  };

  const sortedPortfolios = portfolios
    ?.filter((portfolio) => portfolio?.name.toLowerCase().includes(searchQuery))
    .sort((a, b) => {
      if (orderBy === "created_date" || orderBy === "updated_date") {
        const dateA = new Date(a[orderBy]);
        const dateB = new Date(b[orderBy]);
        return order === "asc" ? dateA - dateB : dateB - dateA;
      }
      return 0;
    });

  const paginatedPortfolios = sortedPortfolios?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const StatusCircle = ({ status, value }) => {
    const colorMap = { green: "#00b38a", red: "#ea324c", amber: "#f2ac42" };
    return (
      <Tooltip title={value} arrow>
        <div
          style={{
            backgroundColor: colorMap[status] || "#0000001f",
            width: "22px",
            height: "22px",
            borderRadius: "50%",
            margin: "0 auto",
          }}
        ></div>
      </Tooltip>
    );
  };

  const handleRestore = (portfolioId, dealId) => {
    try {
      submitPortfolio(portfolioId, dealId);
      window.location.reload();
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    // Call fetchApi immediately and set up polling
    const intervalId = setInterval(() => getPortfolioData(intervalId), 25000);
    getPortfolioData(intervalId);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <Box>
        <Box className="addPortfolioArea">
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            onChange={handleSearchChange}
            style={{ maxWidth: "250px" }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/admin/portco-kb-management/0")}
            className="addPortfolio"
          >
            Add Portfolio
          </Button>
        </Box>
        <Paper>
          <TableContainer style={{ maxHeight: "400px", overflowY: "auto" }}>
            <Table>
              <TableHead
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#fff",
                  zIndex: 1,
                }}
              >
                <TableRow>
                  <TableCell style={{ textAlign: "left" }}>
                    Portfolio Name
                  </TableCell>
                  <TableCell>Deal Cloud ID</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "created_date"}
                      direction={orderBy === "created_date" ? order : "asc"}
                      onClick={() => handleSort("created_date")}
                    >
                      Created Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "updated_date"}
                      direction={orderBy === "updated_date" ? order : "asc"}
                      onClick={() => handleSort("updated_date")}
                    >
                      Modified Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    Portfolio KB <br /> Status
                  </TableCell>
                  <TableCell>
                    Document(s) <br /> Processing Status
                  </TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedPortfolios?.map((portfolio, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ textAlign: "left" }}>
                      {portfolio?.name}
                    </TableCell>
                    <TableCell>{portfolio?.dealid}</TableCell>
                    <TableCell>
                      {new Date(portfolio?.created_date).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      {new Date(portfolio?.updated_date).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      {portfolio?.active ? "Active" : "Inactive"}
                    </TableCell>
                    <TableCell>
                      {portfolio?.processing_status === "Completed" ? (
                        <StatusCircle
                          status="green"
                          value={portfolio?.processing_status}
                        />
                      ) : portfolio?.processing_status === "Failed" ? (
                        <StatusCircle
                          status="red"
                          value={portfolio?.processing_status}
                        />
                      ) : portfolio?.processing_status === "In Progress" ? (
                        <StatusCircle
                          status="amber"
                          value={portfolio?.processing_status}
                        />
                      ) : (
                        <StatusCircle status="" value={"Inactive"} />
                      )}
                    </TableCell>
                    <TableCell>
                      <Tooltip title="View/Edit" arrow>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            navigate(
                              `/admin/portco-kb-management/${portfolio?.dealid}`,
                              {
                                state: portfolio?.dealid,
                              }
                            );
                          }}
                          disabled={!portfolio?.active}
                        >
                          <DifferenceIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete" arrow>
                        <IconButton
                          color="secondary"
                          onClick={() => handleOpenDialog(portfolio?.dealid)}
                          disabled={!portfolio?.active}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Restore" arrow>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            handleRestore(null, portfolio?.dealid);
                          }}
                          disabled={
                            portfolio?.processing_status === "In Progress" ||
                            portfolio?.processing_status === "Completed"
                          }
                        >
                          <RestorePageIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={sortedPortfolios?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
      </Box>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Delete Portfolio</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this portfolio?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
      {isLoading && (
        <Box
          className="loaderPage"
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 2000,
          }}
        >
          <RotateRightIcon
            color="inherit"
            fontSize="large"
            sx={{ animation: "spin 2s infinite ease-in-out" }}
          />
        </Box>
      )}
    </>
  );
};

export default ViewPortfolioList;
