import axios from "axios";
import { environment } from "../../environment";

const buildUrl = (path) => {
  return environment.baseUrl + path;
};

const login = async (email_id, access_token) => {
  try {
    const response = await axios.post(buildUrl("login_api/login"), null, {
      headers: {
        email_id,
        access_token,
      },
    });
console.log(response);
    if (response?.data?.status === "success") {
      localStorage.setItem("admin", response?.data?.data?.is_admin_flag);
    } else {
      throw new Error("Failed to call login API");
    }
  } catch (error) {
    console.error("Error calling login API:", error);
  }
};

export { login };
